import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { FmtService } from '@services/fmt.service';
import { UtilitiesService } from '@services/utilities.service';

import { allKeyMetricsAttr, KeyMetrics, KeyMetricsArray, KeyMetricsAttr, initKeyMetricsArray }
  from '@shared/metrics.interface';
import { Stats, StatsArray } from '@shared/stats.interface';

import { UserState } from '@state/user.state';

@Injectable({ providedIn: 'root' })
export class MetricsService {
  public refreshMetrics$ = new Subject<void>();
  public refreshID = 0;

  public keyMetricsAttr: KeyMetricsAttr[] = [];
  public keyMetricsAttrLookup: { [key: string]: KeyMetricsAttr } = {};
  public sortedKeyMetricsAttr: KeyMetricsAttr[] = [];

  constructor(
    private fmt: FmtService,
    private userState: UserState,
    private util: UtilitiesService,
  ) {
    this.keyMetricsAttr = this.util.deepClone(allKeyMetricsAttr);
    this.refreshMetricFields();
  }

  private refreshMetricFields(): void {
    this.getPrefs();

    for (const attr of this.keyMetricsAttr) {
      this.keyMetricsAttrLookup[attr.id] = attr;
    }
    this.sortedKeyMetricsAttr = this.sortMetricsList();

    this.refreshMetrics$.next();
    this.refreshID++;
  }

  private getPrefs(): void {
    const metricsStr = this.upgradePrefs(this.userState.getPref('metrics', '') as string);
    if (!metricsStr) return;

    for (const metric of metricsStr.split('|')) {
      const [id, visible, slideOrder, overrideName, overrideShortName] = metric.split(',');
      const attr = this.keyMetricsAttrLookup[id];
      if (attr) {
        attr.visible = (attr.profitability && !attr.profitLocationIDs?.length) ||
          (attr.laborCost && !attr.laborCostLocationIDs?.length) ? false : visible === '1';
        attr.slideOrder = parseInt(slideOrder);
        attr.overrideName = decodeURIComponent(overrideName || '');
        attr.overrideShortName = decodeURIComponent(overrideShortName ?? '');
      }
    }
  }

  private upgradePrefs(metricsStr: string): string {
    if (!metricsStr) return '';

    const originalMetricsStr = metricsStr;

    const fields = metricsStr.split('|');
    let profitMarginSortOrder = -1;
    let orderSizeSortOrder = -1;

    const updatedFields = fields.map(field => {
      const [id, visible, sortOrder, overrideName = '', overrideShortName = ''] = field.split(',');
      if (id === 'profitMargin') profitMarginSortOrder = parseInt(sortOrder);
      if (id === 'orderSize') orderSizeSortOrder = parseInt(sortOrder);
      return [id, visible, sortOrder, overrideName, overrideShortName].join(',');
    });

    // Insert Quantity and Products Per Order metrics after Order Size
    if (!metricsStr.includes('quantity') && orderSizeSortOrder !== -1) {
      const quantity = `quantity,1,${orderSizeSortOrder + 1},,`;
      const productsPerOrder = `productsPerOrder,1,${orderSizeSortOrder + 2},,`;
      updatedFields.splice(updatedFields.findIndex(f =>
        f.startsWith('orderSize')) + 1, 0, quantity, productsPerOrder);

      // Increment sortOrder for fields after 'orderSize'
      for (let i = 0; i < updatedFields.length; i++) {
        const parts = updatedFields[i].split(',');
        const currentSortOrder = parseInt(parts[2]);
        if (currentSortOrder > orderSizeSortOrder) {
          parts[2] = (currentSortOrder + 2).toString();
          updatedFields[i] = parts.join(',');
        }
      }
    }

    // Insert Labor Cost metrics after Profit Margin
    if (!metricsStr.includes('laborCost') && profitMarginSortOrder !== -1) {
      const laborCost = `laborCost,1,${profitMarginSortOrder + 1},,`;
      const laborSalesRatio = `laborSalesRatio,1,${profitMarginSortOrder + 2},,`;
      const laborPerOrder = `laborPerOrder,1,${profitMarginSortOrder + 3},,`;
      updatedFields.splice(updatedFields.findIndex(f =>
        f.startsWith('profitMargin')) + 1, 0, laborCost, laborSalesRatio, laborPerOrder);

      // Increment sortOrder for fields after 'profitMargin'
      for (let i = 0; i < updatedFields.length; i++) {
        const parts = updatedFields[i].split(',');
        const currentSortOrder = parseInt(parts[2]);
        if (currentSortOrder > profitMarginSortOrder && !parts[0].includes('labor')) {
          parts[2] = (currentSortOrder + 3).toString();
          updatedFields[i] = parts.join(',');
        }
      }
    }

    // Store upgraded prefs
    const newMetricsStr = updatedFields.join('|');
    if (newMetricsStr !== originalMetricsStr) {
      this.userState.setPref('metrics', newMetricsStr);
    }

    return newMetricsStr;
  }

  private setPrefs(): void {
    const metricsStr = this.keyMetricsAttr.map(attr =>
      `${attr.id},${attr.visible ? '1' : '0'},${attr.slideOrder},` +
      `${encodeURIComponent(attr.overrideName || '')},${encodeURIComponent(attr.overrideShortName ?? '')}`).join('|');
    this.userState.setPref('metrics', metricsStr);
  }

  // Single Point of Entry Functions

  public keyMetricsValues(statsArray: StatsArray): KeyMetricsArray {
    const keyMetricsArray = initKeyMetricsArray(statsArray.sales.length);

    // Initialize array
    const statsByHour: Stats[] = Array.from({ length: statsArray.sales.length }, () => new Stats());

    // Flip StatsArray to array of Stats
    for (const field of Object.keys(statsArray || {})) {
      for (let i = 0; i < statsArray[field as keyof StatsArray].length; i++) {
        statsByHour[i][field as keyof StatsArray] = statsArray[field as keyof StatsArray][i];
      }
    }

    // Calculate value for each metric
    for (let i = 0; i < statsByHour.length; i++) {
      for (const field of Object.keys(keyMetricsArray || {})) {
        keyMetricsArray[field as keyof KeyMetricsArray][i] = this.value(field, statsByHour[i]);
      }
    }

    return keyMetricsArray;
  }

  public keyMetricsTotals(statsArray: StatsArray): KeyMetrics {
    const keyMetrics = new KeyMetrics();
    const statsByHour = new Stats();

    // Flip StatsArray to array of Stats
    for (const field of Object.keys(statsArray || {})) {
      statsByHour[field as keyof StatsArray] = statsArray[field as keyof StatsArray].reduce((acc, value) => acc + value, 0);
    }

    // Calculate value for each metric
    for (const field of Object.keys(keyMetrics || {})) {
      keyMetrics[field as keyof KeyMetrics] = this.value(field, statsByHour);
    }

    return keyMetrics;
  }

  public value(field: string, stats: Stats): number {
    if (!stats) return 0;

    switch (field) {
      case 'sales':
      case 'salesRatio': return this.sales(stats.sales);
      case 'orders':
      case 'ordersRatio': return this.orders(stats.orders);
      case 'orderSize': return this.orderSize(stats.sales, stats.orders);
      case 'productsPerOrder': return this.productsPerOrder(stats.uniqueItems, stats.orders);
      case 'quantity': return this.quantity(stats.quantity);
      case 'itemsPerOrder': return this.itemsPerOrder(stats.quantity, stats.orders);
      case 'profit':
      case 'profitRatio': return this.profit(stats.profit);
      case 'profitMargin': return this.profitMargin(stats.profit, stats.sales);
      case 'laborCost': return this.laborCost(stats.laborCost);
      case 'laborSalesRatio': return this.laborSalesRatio(stats.laborCost, stats.sales);
      case 'laborPerOrder': return this.laborPerOrder(stats.laborCost, stats.orders);
      case 'discountOrders': return this.discountOrders(stats.discountOrders, stats.orders);
      case 'priceDiscount': return this.priceDiscount(stats.discounts, stats.sales);
      case 'repeatCustomers': return this.repeatCustomers(stats.repeatCustomers, stats.trackedCustomers);
      case 'regularCustomers': return this.regularCustomers(stats.regularCustomers, stats.trackedCustomers);
      case 'tips': return this.tips(stats.tips);
      case 'tippingRate': return this.tippingRate(stats.tips, stats.tippableSales);
      case 'refunds': return this.refunds(stats.refunds);
      case 'refundedOrders': return this.refundedOrders(stats.refundedOrders);
      case 'nonRevenue': return this.nonRevenue(stats.nonRevenue);
      case 'nonRevenueOrders': return this.nonRevenueOrders(stats.nonRevenueOrders);
      default: {
        console.error(`APP metrics.value: Invalid metric: ${field}`);
        return 0;
      }
    }
  }

  public deltaValue(field: keyof Stats, totalStats: Stats, currentStats: Stats): number {
    return (totalStats[field] ?? 0) - (currentStats[field] ?? 0);
  }

  public formatArrayField(field: string, statsArray: StatsArray, index: number, isLarge = false,
    isAverage = false): string {

    const stats: Stats = new Stats();
    for (const field of Object.keys(statsArray || {})) {
      stats[field as keyof StatsArray] = statsArray[field as keyof StatsArray][index];
    }
    return this.format(field, stats, isLarge, isAverage);
  }

  public format(field: string, stats: Stats, isLarge = false, isAverage = false): string {
    if (!stats) return '';

    switch (field) {
      case 'sales': return this.salesFmt(stats.sales, isLarge ? 0 : 2);
      case 'orders': return this.ordersFmt(stats.orders, isAverage ? 1 : 0);
      case 'orderSize': return this.orderSizeFmt(stats.sales, stats.orders);
      case 'productsPerOrder': return this.productsPerOrderFmt(stats.uniqueItems, stats.orders);
      case 'quantity': return this.quantityFmt(stats.quantity);
      case 'itemsPerOrder': return this.itemsPerOrderFmt(stats.quantity, stats.orders);
      case 'profit': return this.profitFmt(stats.profit, isLarge ? 0 : 2);
      case 'profitMargin': return this.profitMarginFmt(stats.profit, stats.sales);
      case 'laborCost': return this.laborCostFmt(stats.laborCost, isLarge ? 0 : 2);
      case 'laborSalesRatio': return this.laborSalesRatioFmt(stats.laborCost, stats.sales);
      case 'laborPerOrder': return this.laborPerOrderFmt(stats.laborCost, stats.orders);
      case 'discountOrders': return this.discountOrdersFmt(stats.discountOrders, stats.orders);
      case 'priceDiscount': return this.priceDiscountFmt(stats.discounts, stats.sales);
      case 'repeatCustomers': return this.repeatCustomersFmt(stats.repeatCustomers, stats.trackedCustomers);
      case 'regularCustomers': return this.regularCustomersFmt(stats.regularCustomers, stats.trackedCustomers);
      case 'tips': return this.tipsFmt(stats.tips);
      case 'tippingRate': return this.tippingRateFmt(stats.tips, stats.tippableSales);
      case 'refunds': return this.refundsFmt(stats.refunds);
      case 'refundedOrders': return this.refundedOrdersFmt(stats.refundedOrders, isAverage ? 1 : 0);
      case 'nonRevenue': return this.nonRevenueFmt(stats.nonRevenue);
      case 'nonRevenueOrders': return this.nonRevenueOrdersFmt(stats.nonRevenueOrders, isAverage ? 1 : 0);
      case 'salesRatio':
      case 'ordersRatio':
      case 'profitRatio': return '';
      default: {
        console.error(`APP metrics.format: Invalid calculated metric: ${field}`);
        return '';
      }
    }
  }

  public formatValue(metricID: string, value: number, useShort = false): string {
    if (isNaN(value)) return '';

    const metric = this.keyMetricsAttrLookup[metricID];
    const format = useShort ? metric?.shortFormat : metric?.format;

    switch (format) {
      case 'currency-2': return this.fmt.currency(value);
      case 'currency-0': return this.fmt.currency(value, 0);
      case 'number-0': return this.fmt.number(value);
      case 'number-1': return this.fmt.number(value, 1);
      case 'percent-1': return this.fmt.percent(value, 1);
      case 'percent-2': return this.fmt.percent(value, 2);
      case 'percent-0': return this.fmt.percent(value);
      default: {
        console.error(`APP metrics.formatValue: Unknown format: ${metricID} / ${format}`);
        return '';
      }
    }
  }

  public pattern(metricID: string): RegExp {
    const format = this.keyMetricsAttrLookup[metricID]?.format ?? '';
    return this.formatPattern(metricID, format);
  }

  public formatPattern(field: string, format: string): RegExp {
    switch (format) {
      case 'currency-2': return /^-?[$\d,]+(?:.\d{0,2})?$/;
      case 'currency-0': return /^-?[$\d,]+$/;
      case 'number-0': return /^-?[\d,]+$/;
      case 'number-1': return /^-?[\d,]+(?:\.\d)?$/;
      case '100percent-2': return /^(100(\.00?)?|(\d{1,2}(\.\d{0,2})?))$/;
      case 'percent-2': return /^-?[\d,]+(?:.\d{0,2})?%?$/;
      case 'percent-1': return /^-?[\d,]+(?:\.\d)?%?$/;
      case 'percent-0': return /^-?[\d,]+%?$/;
      default: {
        console.error(`APP metrics.formatPattern: Unknown format for ${field}: ${format}`);
        return /^/;
      }
    }
  }

  public chg(field: string, todayStats: Stats, compStats: Stats): string {
    if (!todayStats || !compStats) return '';

    switch (field) {
      case 'sales': return this.salesChg(todayStats.sales, compStats.sales);
      case 'orders': return this.ordersChg(todayStats.orders, compStats.orders);
      case 'orderSize': return this.orderSizeChg(todayStats.sales, todayStats.orders,
        compStats.sales, compStats.orders);
      case 'productsPerOrder': return this.productsPerOrderChg(todayStats.uniqueItems, todayStats.orders,
        compStats.uniqueItems, compStats.orders);
      case 'quantity': return this.quantityChg(todayStats.quantity, compStats.quantity);
      case 'itemsPerOrder': return this.itemsPerOrderChg(todayStats.quantity, todayStats.orders,
        compStats.quantity, compStats.orders);
      case 'profit': return this.profitChg(todayStats.profit, compStats.profit);
      case 'profitMargin': return this.profitMarginChg(todayStats.profit, todayStats.sales,
        compStats.profit, compStats.sales);
      case 'laborCost': return this.laborCostChg(todayStats.laborCost, compStats.laborCost);
      case 'laborSalesRatio': return this.laborSalesRatioChg(todayStats.laborCost, todayStats.sales,
        compStats.laborCost, compStats.sales);
      case 'laborPerOrder': return this.laborPerOrderChg(todayStats.laborCost, todayStats.orders,
        compStats.laborCost, compStats.orders);
      case 'discountOrders': return this.discountOrdersChg(todayStats.discountOrders, todayStats.orders,
        compStats.discountOrders, compStats.orders);
      case 'priceDiscount': return this.priceDiscountChg(todayStats.discounts, todayStats.sales,
        compStats.discounts, compStats.sales);
      case 'repeatCustomers': return this.repeatCustomersChg(todayStats.repeatCustomers,
        todayStats.trackedCustomers, compStats.repeatCustomers, compStats.trackedCustomers);
      case 'regularCustomers': return this.regularCustomersChg(todayStats.regularCustomers,
        todayStats.trackedCustomers, compStats.regularCustomers, compStats.trackedCustomers);
      case 'tips': return this.tipsChg(todayStats.tips, compStats.tips);
      case 'tippingRate': return this.tippingRateChg(todayStats.tips, todayStats.tippableSales,
        compStats.tips, compStats.tippableSales);
      case 'refunds': return this.refundsChg(todayStats.refunds, compStats.refunds);
      case 'refundedOrders': return this.refundedOrdersChg(todayStats.refundedOrders, compStats.refundedOrders);
      case 'nonRevenue': return this.nonRevenueChg(todayStats.nonRevenue, compStats.nonRevenue);
      case 'nonRevenueOrders': return this.nonRevenueOrdersChg(todayStats.nonRevenueOrders, compStats.nonRevenueOrders);
      default: {
        console.error(`APP metrics.chg: Invalid calculated metric: ${field}`);
        return '';
      }
    }
  }

  public enableProfitability(profitLocationIDs: string[]): void {
    // If the user has chosen to hide profitability, honor that request
    const honorProfitVisibility = this.userState.getPref('honorProfitVisibility', false) as boolean;
    this.keyMetricsAttr = this.keyMetricsAttr.map(attr => {
      return !attr.profitability ? attr : {
        ...attr,
        visible: honorProfitVisibility && profitLocationIDs.length ?
          attr.visible : !!profitLocationIDs.length,
        profitLocationIDs,
      };
    });
    this.refreshMetricFields();
  }

  public enableLaborCost(laborCostLocationIDs: string[]): void {
    // If the user has chosen to hide labor cost, honor that request
    const honorLaborCostVisibility = !this.userState.betaUser ? false :
      this.userState.getPref('honorLaborCostVisibility', false) as boolean;
    this.keyMetricsAttr = this.keyMetricsAttr.map(attr => {
      return !attr.laborCost ? attr : {
        ...attr,
        visible: honorLaborCostVisibility && laborCostLocationIDs.length ?
          attr.visible : !!laborCostLocationIDs.length,
        laborCostLocationIDs,
      };
    });
    this.refreshMetricFields();
  }

  public toggleVisible(metricID: string): void {
    const attr = this.keyMetricsAttrLookup[metricID];
    if (attr) {
      attr.visible = !attr.visible;
      if (attr.profitability) this.userState.setPref('honorProfitVisibility', true);
      if (attr.laborCost) this.userState.setPref('honorLaborCostVisibility', true);
      this.setPrefs();
      this.refreshMetricFields();
    }
  }

  public updateName(metric: KeyMetricsAttr): void {
    const attr = this.keyMetricsAttrLookup[metric.id];
    if (!attr) return;

    attr.name = metric.name;
    if (metric.shortName) attr.shortName = metric.shortName;

    this.setPrefs();
    this.refreshMetricFields();
  }

  public replaceNames(str: string): string {
    this.keyMetricsAttr.forEach(metric => {
      const metricName = metric.overrideName || metric.name;
      // ^ = uppercase, 1 = singular
      str = str
        .replace(new RegExp(`~\\^§${metric.id}~`, 'g'), this.fmt.singular(metricName))
        .replace(new RegExp(`~\\^${metric.id}~`, 'g'), metricName)
        .replace(new RegExp(`~§${metric.id}~`, 'g'), this.fmt.lowerCase(this.fmt.singular(metricName)))
        .replace(new RegExp(`~${metric.id}~`, 'g'), this.fmt.lowerCase(metricName));
    });
    return str;
  }

  public updateSortOrder(from: number, to: number): void {
    const sortedAttr = this.sortedKeyMetricsAttr[from];
    const attr = this.keyMetricsAttrLookup[sortedAttr.id];
    if (attr) attr.slideOrder = to;

    // Adjust remaining metrics
    if (to > from) {
      for (let i = from + 1; i <= to; i++) {
        const sortedAttr = this.sortedKeyMetricsAttr[i];
        const attr = this.keyMetricsAttrLookup[sortedAttr.id];
        if (attr) attr.slideOrder = to > from ? i - 1 : i + 1;
      }
    } else {
      for (let i = to; i <= from - 1; i++) {
        const sortedAttr = this.sortedKeyMetricsAttr[i];
        const attr = this.keyMetricsAttrLookup[sortedAttr.id];
        if (attr) attr.slideOrder = i + 1;
      }
    }
    this.setPrefs();
    this.refreshMetricFields();
  }

  private sortMetricsList(): KeyMetricsAttr[] {
    return this.keyMetricsAttr
      .filter(metric => metric.slideOrder >= 0 && (!metric.laborCost || metric.laborCostLocationIDs?.length))
      .sort((a, b) => a.slideOrder - b.slideOrder);
  }

  public slideOrder(field: string): number {
    const slideMetrics = this.keyMetricsAttr
      .filter(metric => metric.visible && metric.slideOrder >= 0 &&
        (!metric.laborCost || metric.laborCostLocationIDs?.length))
      .sort((a, b) => a.slideOrder - b.slideOrder);

    return slideMetrics.findIndex(metric => metric.id === field) ?? -1;
  }

  public isCalcedMetric(metricID: string): boolean {
    return (this.keyMetricsAttrLookup[metricID]?.stats?.length ?? 0) > 1;
  }

  // Sales Functions

  public sales(sales: number): number {
    return sales ?? 0;
  }

  public salesFmt(sales: number, decimals = 2): string {
    return this.fmt.currency(sales, decimals);
  }

  public salesChg(todaySales: number, compSales: number): string {
    return this.getChangeText(todaySales, compSales);
  }


  // Orders Functions

  public orders(orders: number): number {
    return orders ?? 0;
  }

  public ordersFmt(orders: number, decimals = 0): string {
    return this.fmt.number(orders, decimals);
  }

  public ordersChg(todayOrders: number, compOrders: number): string {
    return this.getChangeText(todayOrders, compOrders);
  }


  // Order Size Functions

  public orderSize(sales: number, orders: number): number {
    return sales && orders ? sales / orders : 0;
  }

  public orderSizeFmt(sales: number, orders: number): string {
    return this.fmt.currency(this.orderSize(sales, orders));
  }

  public orderSizeChg(todaySales: number, todayOrders: number, compSales: number, compOrders: number): string {
    return this.getChangeText(this.orderSize(todaySales, todayOrders),
      this.orderSize(compSales, compOrders));
  }


  // Products Per Order Functions

  public productsPerOrder(uniqueItems: number, orders: number): number {
    return uniqueItems && orders ? uniqueItems / orders : 0;
  }

  public productsPerOrderFmt(uniqueItems: number, orders: number): string {
    return this.fmt.number(this.productsPerOrder(uniqueItems, orders), 1);
  }

  public productsPerOrderChg(todayUniqueItems: number, todayOrders: number, compUniqueItems: number,
    compOrders: number): string {

    return this.getChangeText(this.productsPerOrder(todayUniqueItems, todayOrders),
      this.productsPerOrder(compUniqueItems, compOrders), 1);
  }


  // Quantity Functions

  public quantity(qty: number): number {
    return qty ?? 0;
  }

  public quantityFmt(quantity: number, decimals = 0): string {
    return this.fmt.number(quantity, decimals);
  }

  public quantityChg(todayQuantity: number, compQuantity: number): string {
    return this.getChangeText(todayQuantity, compQuantity);
  }


  // Quantity Per Order Functions

  public itemsPerOrder(items: number, orders: number): number {
    return items && orders ? items / orders : 0;
  }

  public itemsPerOrderFmt(items: number, orders: number): string {
    return this.fmt.number(this.itemsPerOrder(items, orders), 1);
  }

  public itemsPerOrderChg(todayQuantity: number, todayOrders: number, compQuantity: number,
    compOrders: number): string {

    return this.getChangeText(this.itemsPerOrder(todayQuantity, todayOrders),
      this.itemsPerOrder(compQuantity, compOrders), 1);
  }


  // Profit Functions

  public profit(profit: number): number {
    return profit ?? 0;
  }

  public profitFmt(profit: number, decimals = 2): string {
    return this.fmt.currency(profit, decimals);
  }

  public profitChg(todayProfit: number, compProfit: number): string {
    return this.getChangeText(todayProfit, compProfit);
  }


  // Profit Margin Functions

  public profitMargin(profit: number, sales: number): number {
    return profit && sales ? profit / sales : 0;
  }

  public profitMarginFmt(profit: number, sales: number): string {
    return this.fmt.percent(this.profitMargin(profit, sales), 1);
  }

  public profitMarginChg(todayProfitMargin: number, todaySales: number, compProfitMargin: number,
    compSales: number): string {

    const current = this.profitMargin(todayProfitMargin, todaySales);
    const comp = this.profitMargin(compProfitMargin, compSales);
    return this.getChangeText(current, comp, 3);
  }


  // Labor Cost Functions

  public laborCost(laborCost: number): number {
    return laborCost ?? 0;
  }

  public laborCostFmt(laborCost: number, decimals = 2): string {
    return this.fmt.currency(laborCost, decimals);
  }

  public laborCostChg(todayLaborCost: number, compLaborCost: number): string {
    return this.getChangeText(todayLaborCost, compLaborCost);
  }


  // Labor/Sales % Functions

  public laborSalesRatio(laborCost: number, sales: number): number {
    return !laborCost ? 0 : !sales ? Infinity : laborCost / sales;
  }

  public laborSalesRatioFmt(laborCost: number, sales: number): string {
    return this.fmt.percent(this.laborSalesRatio(laborCost, sales), 1);
  }

  public laborSalesRatioChg(todayLaborCost: number, todaySales: number, compLaborCost: number,
    compSales: number): string {

    const current = this.laborSalesRatio(todayLaborCost, todaySales);
    const comp = this.laborSalesRatio(compLaborCost, compSales);
    return this.getChangeText(current, comp, 3);
  }


  // Labor Per Order Functions

  public laborPerOrder(laborCost: number, orders: number): number {
    return laborCost && orders ? laborCost / orders : 0;
  }

  public laborPerOrderFmt(laborCost: number, orders: number): string {
    return this.fmt.currency(this.laborPerOrder(laborCost, orders));
  }

  public laborPerOrderChg(todayLaborCost: number, todayOrders: number, compLaborCost: number,
    compOrders: number): string {

    const current = this.laborPerOrder(todayLaborCost, todayOrders);
    const comp = this.laborPerOrder(compLaborCost, compOrders);
    return this.getChangeText(current, comp, 3);
  }


  // Discount Orders Functions

  public discountOrders(discountOrders: number, orders: number): number {
    return discountOrders && orders ? discountOrders / orders : 0;
  }

  public discountOrdersFmt(discountOrders: number, orders: number): string {
    return this.fmt.percent(this.discountOrders(discountOrders, orders), 1);
  }

  public discountOrdersChg(todayDiscountOrders: number, todayOrders: number, compDiscountOrders: number,
    compOrders: number): string {

    return this.getChangeText(this.discountOrders(todayDiscountOrders, todayOrders),
      this.discountOrders(compDiscountOrders, compOrders), 3);
  }


  // Price Discount Functions

  public priceDiscount(discounts: number, sales: number): number {
    return discounts && sales && sales + discounts !== 0 ? discounts / (sales + discounts) :
      sales === 0 && discounts !== 0 ? 1 : 0;
  }

  public priceDiscountFmt(discounts: number, sales: number, decimals = 1): string {
    const priceDiscount = this.priceDiscount(discounts, sales);
    if (decimals === 0 && priceDiscount < 0.005) decimals = 1;  // Force one decimal place for small discounts
    return this.fmt.percent(priceDiscount, decimals);
  }

  public priceDiscountChg(todayDiscounts: number, todaySales: number, compDiscounts: number, compSales: number): string {
    return this.getChangeText(this.priceDiscount(todayDiscounts, todaySales),
      this.priceDiscount(compDiscounts, compSales), 3);
  }


  // Repeat Customers Functions

  public repeatCustomers(repeatCustomers: number, trackedCustomers: number): number {
    return repeatCustomers && trackedCustomers ? repeatCustomers / trackedCustomers : 0;
  }

  public repeatCustomersFmt(repeatCustomers: number, trackedCustomers: number, decimals = 1): string {
    return this.fmt.percent(this.repeatCustomers(repeatCustomers, trackedCustomers), decimals);
  }

  public repeatCustomersChg(todayRepeatCustomers: number, todayTrackedCustomers: number, compRepeatCustomers: number,
    compTrackedCustomers: number): string {

    return this.getChangeText(this.repeatCustomers(todayRepeatCustomers, todayTrackedCustomers),
      this.tippingRate(compRepeatCustomers, compTrackedCustomers), 3);
  }


  // Regular Customers Functions

  public regularCustomers(regularCustomers: number, trackedCustomers: number): number {
    return regularCustomers && trackedCustomers ? regularCustomers / trackedCustomers : 0;
  }

  public regularCustomersFmt(regularCustomers: number, trackedCustomers: number, decimals = 1): string {
    return this.fmt.percent(this.regularCustomers(regularCustomers, trackedCustomers), decimals);
  }

  public regularCustomersChg(todayRegularCustomers: number, todayTrackedCustomers: number,
    compRegularCustomers: number, compTrackedCustomers: number): string {

    return this.getChangeText(this.repeatCustomers(todayRegularCustomers, todayTrackedCustomers),
      this.tippingRate(compRegularCustomers, compTrackedCustomers), 3);
  }


  // Tips Functions

  public tips(tips: number): number {
    return tips ?? 0;
  }

  public tipsFmt(tips: number, decimals = 2): string {
    return this.fmt.currency(tips, decimals);
  }

  public tipsChg(todayTips: number, compTips: number): string {
    return this.getChangeText(todayTips, compTips);
  }


  // Tipping Rate Functions

  public tippingRate(tips: number, sales: number): number {
    return tips && sales ? tips / sales : 0;
  }

  public tippingRateFmt(tips: number, sales: number, decimals = 1): string {
    return this.fmt.percent(this.tippingRate(tips, sales), decimals);
  }

  public tippingRateChg(todayTips: number, todaySales: number, compTips: number, compSales: number): string {
    return this.getChangeText(this.tippingRate(todayTips, todaySales), this.tippingRate(compTips, compSales), 3);
  }


  // Refunds Functions

  public refunds(refunds: number): number {
    return refunds ?? 0;
  }

  public refundsFmt(refunds: number, decimals = 2): string {
    return this.fmt.currency(refunds, decimals);
  }

  public refundsChg(todayRefunds: number, compRefunds: number): string {
    return this.getChangeText(todayRefunds, compRefunds);
  }


  // Refunded Orders Functions

  public refundedOrders(refundedOrders: number): number {
    return refundedOrders ?? 0;
  }

  public refundedOrdersFmt(refundedOrders: number, decimals = 0): string {
    return this.fmt.number(refundedOrders, decimals);
  }

  public refundedOrdersChg(todayRefundedOrders: number, compRefundedOrders: number): string {
    return this.getChangeText(todayRefundedOrders, compRefundedOrders);
  }


  // Non-Revenue Functions

  public nonRevenue(nonRevenue: number): number {
    return nonRevenue ?? 0;
  }

  public nonRevenueFmt(nonRevenue: number, decimals = 2): string {
    return this.fmt.currency(nonRevenue, decimals);
  }

  public nonRevenueChg(todayNonRevenue: number, compNonRevenue: number): string {
    return this.getChangeText(todayNonRevenue, compNonRevenue);
  }


  // Non-Revenue Orders Functions

  public nonRevenueOrders(nonRevenueOrders: number): number {
    return nonRevenueOrders ?? 0;
  }

  public nonRevenueOrdersFmt(nonRevenueOrders: number, decimals = 0): string {
    return this.fmt.number(nonRevenueOrders, decimals);
  }

  public nonRevenueOrdersChg(todayNonRevenueOrders: number, compNonRevenueOrders: number): string {
    return this.getChangeText(todayNonRevenueOrders, compNonRevenueOrders);
  }


  // INTERNAL SUPPORT FUNCTIONS

  private getChangePercent(current: number, comp: number, decimals = 2): number {
    return comp !== 0 ? this.util.round((this.util.round(current, decimals) /
      this.util.round(comp, decimals)) - 1, 2) : 999999;
  }

  private getChangeText(current: number, comp: number, decimals = 2): string {
    if (current === 0 && comp === 0) return 'No change';
    if (isNaN(comp) || comp === 0) return '+∞';

    const changePercent = this.getChangePercent(current, comp, decimals);

    if (changePercent === 0) return 'No change';
    if (changePercent > 999.99) return '+∞';
    if (changePercent > 9.99) return `+${String(this.util.round(changePercent))}X`;
    if (changePercent > 0) return `+${this.fmt.percent(changePercent)}`;
    return this.fmt.percent(changePercent);
  }
}
