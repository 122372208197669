import { ErrorHandler, Inject, Injectable, Provider } from '@angular/core';

import { captureConsoleIntegration, init } from '@sentry/browser';

import { EnvService } from '@services/env.service';
import { ErrorService } from '@services/error.service';

@Injectable({ providedIn: 'root' })
export class SentryErrorHandlerProvider implements ErrorHandler {
  constructor(
    @Inject(EnvService) private env: EnvService,
    @Inject(ErrorService) private error: ErrorService,
  ) {
    if (this.env.useSentry) {
      init({
        dsn: 'https://baf32edb1fae449b941f43188d6ac0f8@o413292.ingest.sentry.io/5298407',
        environment: this.env.name,
        debug: false,
        release: this.env.version,
        ignoreErrors: this.error.toIgnore(),
        integrations: [
          captureConsoleIntegration({ levels: ['error'] })],
      });
    }
  }

  public async handleError(error: Error): Promise<void> {
    if (this.env.test) {
      console.error(error);
    } else {
      await this.error.handleError(error);
    }
  }
}

export function provideSentryErrorHandler(): Provider {
  return { provide: ErrorHandler, useClass: SentryErrorHandlerProvider };
}
