import { Injectable } from '@angular/core';

import { SettingsService } from '@settings/settings.service';
import { SourcesService } from '@services/sources.service';

import { LocationSettings } from '@shared/settings.interface';

import { UserState } from '@state/user.state';

@Injectable({ providedIn: 'root' })
export class GoogleService {
  public locationsWithoutGoogle: LocationSettings[] = [];

  constructor(
    private settings: SettingsService,
    private sources: SourcesService,
    private userState: UserState,
  ) { }

  public promptForGoogle(locations: LocationSettings[]): boolean {
    if (!(this.userState.getPref('promptForGoogle', true) as boolean) ||
      this.userState.user.parentIDs.length) return false;
    this.getLocationsWithoutGoogle(locations);
    return this.locationsWithoutGoogle.length > 0;
  }

  private getLocationsWithoutGoogle(locations: LocationSettings[]): void {
    const authLocations = this.settings.getAuthLocations(locations);

    this.locationsWithoutGoogle = !authLocations?.length ? [] : locations.filter(location => {
      const posSourceIDs = (this.sources.posSources() ?? []).map(source => source.id);
      const posActive = location.connections.some(connection =>
        posSourceIDs.includes(connection.sourceID) && connection.active);
      const googleActive = location.connections.some(connection =>
        connection.sourceID === 'GO' && connection.active);
      return posActive && !googleActive &&
        (!this.userState.user.locationIDs.length || this.userState.user.locationIDs.includes(location.locationID));
    });
  }
}
