import { DestroyRef, Injectable } from '@angular/core';

import { merge } from 'rxjs';

import { DarkService } from '@services/dark.service';
import { SignoutService } from '@services/signout.service';

export interface Color {
  color: string;
  lightColor: string;
  contrastColor: string;
  inUse?: boolean;
}
@Injectable({ providedIn: 'root' })
export class ColorsService {
  public background = '';

  public channelColors: Color[] = [
    { color: '#0187ea', lightColor: '#24a1fe', contrastColor: '#FFFFFF', inUse: false },
    { color: '#ed7d9b', lightColor: '#f197af', contrastColor: '#000000', inUse: false },
    { color: '#febb20', lightColor: '#fec94d', contrastColor: '#000000', inUse: false },
    { color: '#94d926', lightColor: '#a9e151', contrastColor: '#000000', inUse: false },
    { color: '#b29ddc', lightColor: '#c1b1e3', contrastColor: '#000000', inUse: false },
    { color: '#f8872b', lightColor: '#f99f55', contrastColor: '#FFFFFF', inUse: false },
    { color: '#33c3af', lightColor: '#58d3c2', contrastColor: '#FFFFFF', inUse: false },
    { color: '#a17139', lightColor: '#c28e52', contrastColor: '#FFFFFF', inUse: false },
    { color: '#71bcf3', lightColor: '#8dc9f5', contrastColor: '#000000', inUse: false },
    { color: '#e54d77', lightColor: '#ea7192', contrastColor: '#FFFFFF', inUse: false },
    { color: '#ffd46f', lightColor: '#ffdd8c', contrastColor: '#000000', inUse: false },
    { color: '#9071cc', lightColor: '#a68dd6', contrastColor: '#FFFFFF', inUse: false },
    { color: '#b6e171', lightColor: '#c5e78d', contrastColor: '#000000', inUse: false },
    { color: '#f6a86a', lightColor: '#f8b988', contrastColor: '#000000', inUse: false },
    { color: '#87ddd1', lightColor: '#9fe4da', contrastColor: '#000000', inUse: false },
    { color: '#b4936c', lightColor: '#c3a989', contrastColor: '#000000', inUse: false },
  ];

  public trendsColors: Color[] = [
    { color: '#0187ea', lightColor: '#34AAFE', contrastColor: '#FFFFFF' },
    { color: '#fd7f6f', lightColor: '#FEB8AF', contrastColor: '#000000' },
    { color: '#7eb0d5', lightColor: '#B2CFE6', contrastColor: '#000000' },
    { color: '#b2e061', lightColor: '#CDEB99', contrastColor: '#000000' },
    { color: '#bd7ebe', lightColor: '#D5AED6', contrastColor: '#FFFFFF' },
    { color: '#ffb55a', lightColor: '#FFD199', contrastColor: '#000000' },
    { color: '#beb9db', lightColor: '#E6E4F1', contrastColor: '#000000' },
    { color: '#fdcce5', lightColor: '#FEEBF5', contrastColor: '#000000' },
    { color: '#8bd3c7', lightColor: '#C4E9E2', contrastColor: '#000000' },
    { color: '#ffee65', lightColor: '#FFF5AD', contrastColor: '#000000' },
    { color: '#B7B5BA', lightColor: '#D6D5D7', contrastColor: '#000000' },
  ];

  public colors: { [key: string]: string; } = {};
  public gradients: { [key: string]: string; } = {};

  constructor(
    private dark: DarkService,
    private destroyRef: DestroyRef,
    private signout: SignoutService,
  ) {
    this.updateColors();

    merge(this.dark.darkMode$, this.dark.colorScheme$)
      .pipe(this.signout.takeUntil(this.destroyRef))
      .subscribe(() => this.updateColors());
  }

  public updateColors(): void {
    if (this.dark.colorScheme === 1) {
      this.colors['header'] = this.dark.showDark ? '#737373' : '#262626';
      this.colors['header-dark'] = this.dark.showDark ? '#808080' : '#000000';
    } else {
      this.colors['header'] = '#8b0000';
      this.colors['header-dark'] = '#660000';
    }

    this.colors['popup-header'] = this.dark.showDark ? '#737373' : '#262626';
    this.colors['popup-header-dark'] = this.dark.showDark ? '#808080' : '#000000';

    this.colors['primary'] = '#8b0000';
    this.colors['accent'] = this.dark.showDark ? '#00a3a3' : '#008b8b';
    this.colors['accent-light'] = this.dark.showDark ? '#00c4c4' : '#00a3a3';
    this.colors['accent-contrast'] = this.dark.showDark ? '#000000' : '#FFFFFF';
    this.colors['secondary'] = this.dark.showDark ? '#737373' : '#262626';
    this.colors['secondary-light'] = this.dark.showDark ? '#666666' : '#404040';
    this.colors['tertiary'] = '#d11345';
    this.colors['positive'] = '#4daf1b';
    this.colors['positive-light'] = '#5DD421';
    this.colors['negative'] = '#e12d39';
    this.colors['white'] = this.dark.showDark ? '#000000' : '#ffffff';
    this.colors['gray-10'] = this.dark.showDark ? '#535353' : '#e6e6e6';
    this.colors['gray-15'] = this.dark.showDark ? '#5c5c5c' : '#d9d9d9';
    this.colors['gray-20'] = this.dark.showDark ? '#666666' : '#cccccc';
    this.colors['gray-30'] = this.dark.showDark ? '#7a7a7a' : '#b3b3b3';
    this.colors['gray-45'] = this.dark.showDark ? '#969696' : '#8c8c8c';
    this.colors['gray-50'] = this.dark.showDark ? '#a0a0a0' : '#808080';
    this.colors['gray-60'] = this.dark.showDark ? '#b2b2b2' : '#666666';
    this.colors['gray-85'] = this.dark.showDark ? '#e2e2e2' : '#262626';
    this.colors['black'] = this.dark.showDark ? '#ffffff' : '#000000';
    this.colors['dark'] = this.dark.showDark ? '#ffffff' : '#262626';
    this.colors['dark-contrast'] = this.dark.showDark ? '#000000' : '#ffffff';

    this.gradients = {};
    for (const channel of this.channelColors) {
      this.gradients[channel.color] = `linear-gradient(${channel.lightColor}, ${channel.color})`;
    }
    for (const trend of this.trendsColors) {
      this.gradients[trend.color] = `linear-gradient(${trend.lightColor}, ${trend.color})`;
    }
  }

  public get(color: string, opacity?: number): string {
    let hexColor = !color || typeof color !== 'string' ? undefined : color.startsWith('#') ? color :
      color.startsWith('rgba') ? this.rgbaToHex(color) : this.colors[color];
    if (!hexColor) {
      console.error(`Color not found: ${color}`);
      hexColor = '#ffffff';
    }
    return typeof opacity !== 'undefined' && opacity >= 0 ? this.hexToRgb(hexColor, opacity) : hexColor;
  }

  public getTrend(index: number, opacity: number): Color {
    if (index < 0 || index > this.trendsColors.length - 1) index = 0;
    return {
      color: this.hexToRgb(this.trendsColors[index].color, opacity),
      lightColor: this.hexToRgb(this.trendsColors[index].lightColor, opacity),
      contrastColor: this.hexToRgb(this.trendsColors[index].contrastColor, opacity),
    };
  }

  public getGradient(colorLight: string, color: string, opacity?: number): string {
    return `linear-gradient(${this.get(colorLight, opacity)}, ${this.get(color, opacity)})`;
  }

  private hexToRgb(hex: string, opacity = 1): string {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) {
      console.error(`Invalid color conversion: ${hex}`);
      return `rgba(0, 0, 0, ${String(opacity)}`;
    }

    return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ` +
      `${parseInt(result[3], 16)}, ${String(opacity)})`;
  }

  private rgbaToHex(rgba: string): string {
    const regExPattern = /^rgba\((\d+),\s?(\d+),\s?(\d+)/;
    const match = regExPattern.exec(rgba);
    if (!match) {
      console.error(`Invalid RGBA value ${rgba}`);
      return '';
    }

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const [_fullMatch, r, g, b] = match;
    const red = parseInt(r).toString(16).padStart(2, '0');
    const green = parseInt(g).toString(16).padStart(2, '0');
    const blue = parseInt(b).toString(16).padStart(2, '0');

    return `#${red}${green}${blue}`;
  }

  public reserveColor(): string {
    const index = this.channelColors.findIndex(color => !color.inUse);
    if (index === -1) {
      console.error(`APP settings.reserveColors: All colors are used`);
      return '#FFF';
    }

    this.channelColors[index].inUse = true;
    return this.channelColors[index].color;
  }

  public getContrastColor(color: string, percentage = 25): string {
    let r = parseInt(color.substring(1, 3), 16);
    let g = parseInt(color.substring(3, 5), 16);
    let b = parseInt(color.substring(5, 7), 16);

    const factor = 1 - percentage / 100;
    r = Math.min(255, Math.max(0, Math.round(r * factor)));
    g = Math.min(255, Math.max(0, Math.round(g * factor)));
    b = Math.min(255, Math.max(0, Math.round(b * factor)));

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  }
}
