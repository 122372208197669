import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import moment from 'moment-timezone';
moment.updateLocale('en', { week: { dow: 1 } });

export const ONE_YEAR = 398;
export const FIVE_YEARS = 1830;

interface DayOfWeek {
  name: string,
  shortName: string
  letter: string;
  value: number;
  valueStr: string;
}

@Injectable({ providedIn: 'root' })
export class DtService {

  public nowChanged$ = new Subject<boolean>();

  public timezones = [
    {
      name: `Newfoundland Time (St. John's)`,
      shortName: `Newfoundland Time`,
      value: 'America/St_Johns',
    },
    {
      name: 'Atlantic Time (Halifax)',
      shortName: 'Atlantic Time',
      value: 'America/Halifax',
    },
    {
      name: 'Eastern Time (New York)',
      shortName: 'Eastern Time',
      value: 'America/New_York',
    },
    {
      name: 'Central Time (Chicago)',
      shortName: 'Central Time',
      value: 'America/Chicago',
    },
    {
      name: 'Mountain Time (Denver)',
      shortName: 'Mountain Time (Denver)',
      value: 'America/Denver',
    },
    {
      name: 'Mountain Time (Phoenix)',
      shortName: 'Mountain Time (Phoenix)',
      value: 'America/Phoenix',
    },
    {
      name: 'Pacific Time (Los Angeles)',
      shortName: 'Pacific Time',
      value: 'America/Los_Angeles',
    },
    {
      name: 'Alaska Time (Anchorage)',
      shortName: 'Alaska Time',
      value: 'America/Anchorage',
    },
    {
      name: 'Hawaii-Aleutian Time (Honolulu)',
      shortName: 'Hawaii-Aleutian Time',
      value: 'Pacific/Honolulu',
    },
  ];

  private sunday: DayOfWeek[] = [
    { name: 'Sunday', shortName: 'Sun', letter: 'Su', value: 0, valueStr: '0' },
    { name: 'Monday', shortName: 'Mon', letter: 'M', value: 1, valueStr: '1' },
    { name: 'Tuesday', shortName: 'Tue', letter: 'T', value: 2, valueStr: '2' },
    { name: 'Wednesday', shortName: 'Wed', letter: 'W', value: 3, valueStr: '3' },
    { name: 'Thursday', shortName: 'Thu', letter: 'Th', value: 4, valueStr: '4' },
    { name: 'Friday', shortName: 'Fri', letter: 'F', value: 5, valueStr: '5' },
    { name: 'Saturday', shortName: 'Sat', letter: 'Sa', value: 6, valueStr: '6' },
  ];

  private monday: DayOfWeek[] = [
    { name: 'Monday', shortName: 'Mon', letter: 'M', value: 0, valueStr: '0' },
    { name: 'Tuesday', shortName: 'Tue', letter: 'T', value: 1, valueStr: '1' },
    { name: 'Wednesday', shortName: 'Wed', letter: 'W', value: 2, valueStr: '2' },
    { name: 'Thursday', shortName: 'Thu', letter: 'Th', value: 3, valueStr: '3' },
    { name: 'Friday', shortName: 'Fri', letter: 'F', value: 4, valueStr: '4' },
    { name: 'Saturday', shortName: 'Sat', letter: 'Sa', value: 5, valueStr: '5' },
    { name: 'Sunday', shortName: 'Sun', letter: 'Su', value: 6, valueStr: '6' },
  ];

  public daysOfWeek: DayOfWeek[] = this.monday;

  public nowStr = moment().format('YYYY-MM-DD');
  public yesterdayStr = this.subtractDateStr(this.nowStr, 1);
  public tomorrowStr = this.addDateStr(this.nowStr, 1);
  public maxDate = this.addDateStr(this.nowStr, 6);
  public currentYear = this.format(this.nowStr, 'YYYY');
  public oldestDate = moment(this.subtractDate(this.nowStr, FIVE_YEARS)).toDate();
  public oldestDateStr = moment(this.subtractDate(this.nowStr, FIVE_YEARS)).format('YYYY-MM-DD');
  public timezone = '';

  constructor() {
    const refreshOnTimer = () => {
      const newNowStr = moment().format('YYYY-MM-DD');
      if (this.nowStr !== newNowStr) {
        this.nowStr = newNowStr;
        this.yesterdayStr = this.subtractDateStr(this.nowStr, 1);
        this.tomorrowStr = this.addDateStr(this.nowStr, 1);
        this.maxDate = this.addDateStr(this.nowStr, 6);
        this.currentYear = this.format(this.nowStr, 'YYYY');
        this.oldestDate = moment(this.subtractDate(this.nowStr, FIVE_YEARS)).toDate();
        this.oldestDateStr = moment(this.subtractDate(this.nowStr, FIVE_YEARS)).format('YYYY-MM-DD');
        this.nowChanged$.next(true);
      }
    };
    window.setInterval(refreshOnTimer, 60 * 1000);
  }

  public getTimezone(): string {
    return this.timezone;
  }

  public updateTimezone(timezone: string): void {
    moment.tz.setDefault(timezone);
    this.timezone = timezone;
  }

  public updateStartDayOfWeek(startDayOfWeek = 1): void {
    moment.updateLocale('en', { week: { dow: startDayOfWeek } });
    this.daysOfWeek = startDayOfWeek === 1 ? this.monday : this.sunday;
  }

  public adjustStartDayOfWeek(days: string, startDayOfWeek: number): string {
    // Choose the correct mapping based on the direction of conversion
    const sundayToMondayMap = [6, 0, 1, 2, 3, 4, 5];
    const mondayToSundayMap = [1, 2, 3, 4, 5, 6, 0];
    const map = startDayOfWeek === 1 ? sundayToMondayMap : mondayToSundayMap;

    return days.split('').map(Number).map(day => map[day]).sort((a, b) => a - b).join('');
  }

  public getCurrentTime(): number {
    return this.getTime(this.now());
  }

  public getCurrentUTCTime(): number {
    return Number(moment.utc().format('H'));
  }

  public getMonth(date?: Date | string): string {
    return moment(date ?? this.now()).format('MMMM').toLowerCase();
  }

  public getTime(date: Date | string): number {
    return Number(moment(date).format('H')) +
      (Number(moment(date).format('m')) / 60) +
      (Number(moment(date).format('s')) / 3600);
  }

  public getDateFromTime(time: number, date?: string): Date {
    date = date ?? this.nowStr;
    return moment(date)
      .add(parseInt(String(time)), 'hours')
      .add(60 * (time - parseInt(String(time))), 'minutes').toDate();
  }

  public getTimeFormatted(time: number, format = 'H:mm'): string {
    return moment(this.getDateFromTime(time)).format(format);
  }

  public now(): Date {
    return moment().toDate();
  }

  public nowDateTime(): string {
    return moment().format('llll');
  }

  public newDate(date: Date | string | number): Date {
    return moment(new Date(date)).toDate();
  }

  public isValid(date: string, checkRange = true): boolean {
    const momentDate = moment(date, 'YYYY-MM-DD', true); // Strict format validation
    if (!checkRange) return momentDate.isValid();

    const yearMinus5 = this.now().getFullYear() - 5;
    const yearPlus5 = this.now().getFullYear() + 5;
    return momentDate.isValid() && momentDate.year() >= yearMinus5 && momentDate.year() <= yearPlus5;
  }

  public format(date: Date | string, format = 'YYYY-MM-DD'): string {
    return moment(date).format(format);
  }

  public formatUTC(date: Date | string, format = 'YYYY-MM-DD'): string {
    return moment(date).utc().format(format);
  }

  public formatFullDate(date: Date | string): string {
    return moment(date).format('dddd, MMMM D, YYYY');
  }

  public formatYYMMDDHHmmss(time: number): string {
    const dateStr = moment(time).utc().toISOString();
    const regExPattern = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
    const match = regExPattern.exec(dateStr);

    if (match) {
      const [, year, month, day, hour, minute, second] = match;
      return `${year.slice(-2)}${month}${day}${hour}${minute}${second}`;
    } else {
      return '';
    }
  }

  public addDate(date: Date | string, quantity: number, units = 'days'): Date {
    return moment(date).add(quantity, units as moment.DurationInputArg2).toDate();
  }

  public addDateStr(date: Date | string, quantity: number, units = 'days'): string {
    return moment(date).add(quantity, units as moment.DurationInputArg2).format('YYYY-MM-DD');
  }

  public subtractDate(date: Date | string, quantity: number, units = 'days'): Date {
    return moment(date).subtract(quantity, units as moment.DurationInputArg2).toDate();
  }

  public subtractDateStr(date: Date | string, quantity: number, units = 'days'): string {
    return moment(date).subtract(quantity, units as moment.DurationInputArg2).format('YYYY-MM-DD');
  }

  public subtractMonthsStr(date: string, months: number): string {
    return this.subtractDateStr(this.addDateStr(date, 1, 'day'), months, 'month');
  }

  public startOf(date: Date | string, units = 'day'): string {
    return moment(date).startOf(units as moment.unitOfTime.StartOf).format('YYYY-MM-DD');
  }

  public endOf(date: Date | string, units = 'day'): string {
    return moment(date).endOf(units as moment.unitOfTime.StartOf).format('YYYY-MM-DD');
  }

  public dayOfWeek(date: Date | string, startDayOfWeek: number): number {
    const weekday = moment(date).isoWeekday();
    if (startDayOfWeek === 0) {
      return weekday % 7; // Adjusts so that Sunday = 0, Monday = 1, ..., Saturday = 6
    } else {
      return weekday - 1; // Default is ISO week (Monday = 0, Sunday = 6)
    }
  }

  public dayOfWeekStr(date: Date | string): string {
    return moment(date).format('dddd');
  }

  public isWeekend(date: Date | string, startDayOfWeek: number): boolean {
    return this.isSaturday(date, startDayOfWeek) || this.isSunday(date, startDayOfWeek);
  }

  public isWeekday(date: Date | string, startDayOfWeek: number): boolean {
    return !this.isWeekend(date, startDayOfWeek);
  }

  public isSaturday(date: Date | string, startDayOfWeek: number): boolean {
    const dayOfWeek = this.dayOfWeek(date, startDayOfWeek);
    return (startDayOfWeek === 0 && dayOfWeek === 6) || (startDayOfWeek === 1 && dayOfWeek === 5);
  }

  public isSunday(date: Date | string, startDayOfWeek: number): boolean {
    const dayOfWeek = this.dayOfWeek(date, startDayOfWeek);
    return (startDayOfWeek === 0 && dayOfWeek === 0) || (startDayOfWeek === 1 && dayOfWeek === 6);
  }

  public dayOfMonth(date?: Date | string): number {
    if (!date) date = this.now().getDay() === 1 ? this.nowStr : this.yesterdayStr;
    return moment(date).date();
  }

  public dayOfQuarter(date?: Date | string): number {
    if (!date) date = this.now().getDay() === 1 ? this.nowStr : this.yesterdayStr;
    return this.dateDiff(moment(date).toDate(), moment().startOf('quarter').toDate()) + 1;
  }

  public dayOfYear(date?: Date | string): number {
    if (!date) date = this.now().getDay() === 1 ? this.nowStr : this.yesterdayStr;
    return this.dateDiff(moment(date).toDate(), moment().startOf('year').toDate()) + 1;
  }

  public daysInMonth(date: Date | string): number {
    return moment(date).daysInMonth();
  }

  public daysInQuarter(date: Date | string): number {
    const currentQuarter = moment(date).quarter();
    const startOfQuarter = moment().quarter(currentQuarter).startOf('quarter');
    const endOfQuarter = moment().quarter(currentQuarter).endOf('quarter');
    const daysInQuarter = endOfQuarter.diff(startOfQuarter, 'days') + 1;
    return daysInQuarter;
  }

  public daysInYear(date: Date | string): number {
    const currentYear = moment(date).year();
    const daysInYear = moment(new Date(currentYear, 11, 31)).dayOfYear();
    return daysInYear;
  }

  public dayWeekOfMonth(date: string, dayOfWeek: number, week: number, startDayOfWeek: number): string {
    // Convert to moment days of week if needed (Monday = 0, Sunday = 6 => Sunday = 0, Saturday = 6)
    if (startDayOfWeek === 1) dayOfWeek = Number(this.adjustStartDayOfWeek(String(dayOfWeek), 0));

    // Special handling for last week of month (Week 4)
    if (week === 4) {
      const lastDayOfMonth = this.endOf(date, 'month');
      const lastDayOfWeek = moment(lastDayOfMonth).day(dayOfWeek);
      return this.format(lastDayOfWeek.subtract(lastDayOfWeek.isAfter(lastDayOfMonth) ? 1 : 0, 'week').toDate());
    }

    const firstDayOfMonth = this.startOf(date, 'month');
    const firstDayOfWeek = moment(firstDayOfMonth).day(dayOfWeek);
    return this.format(firstDayOfWeek.add(week + (firstDayOfWeek.isBefore(firstDayOfMonth) ? 1 : 0), 'week').toDate());
  }

  public dayQuarter(date: string, dayOfQuarter: number): string {
    return dayOfQuarter === 92 ? moment(date).endOf('quarter').format('YYYY-MM-DD') :
      moment(date).startOf('quarter').add(dayOfQuarter, 'day').format('YYYY-MM-DD');
  }

  public getMostRecent(dateStr: string, day: string, startDayOfWeek: number, includeToday = true): string {
    const daySeq = this.daysOfWeek.map(day => day.shortName).indexOf(day);
    const date = moment(dateStr).toDate();
    const dayOfWeek = this.dayOfWeek(date, startDayOfWeek);
    return this.subtractDateStr(date, dayOfWeek - daySeq +
      (includeToday ? (dayOfWeek - daySeq < 0 ? 7 : 0) : (dayOfWeek - daySeq <= 0 ? 7 : 0)));
  }

  public isHistorical(date: Date | string, minDate: string): boolean {
    const dateStr = moment(date).format('YYYY-MM-DD');
    return dateStr >= minDate && dateStr < this.nowStr;
  }

  public dateDiff(newestDate: Date | string, oldestDate: Date | string, timeframe = 'days'): number {
    return moment(newestDate).diff(moment(oldestDate), timeframe as moment.unitOfTime.Diff);
  }

  public daysAgo(date: Date | string): number {
    return moment(this.now()).diff(date, 'days');
  }

  public subtractTimeframe(date: Date | string, quantity: number, timeframe: string): number {
    return moment(date).diff(moment(date).subtract(quantity, timeframe as moment.unitOfTime.Diff), 'days');
  }

  public startOfPrior(date: Date | string, quantity: number, units: string): string {
    return moment(this.subtractDateStr(date, quantity, units))
      .startOf(units as moment.unitOfTime.StartOf)
      .format('YYYY-MM-DD');
  }

  public endOfPrior(date: Date | string, quantity: number, units: string): string {
    return moment(this.subtractDateStr(date, quantity, units))
      .endOf(units as moment.unitOfTime.StartOf)
      .format('YYYY-MM-DD');
  }

  public formatTimeLong(hour: string | number, endTime = false): string {
    hour = String(hour);
    let time = this.formatTime(hour, endTime);
    time = time.replace('12:00am', '12:00am (midnight)');
    time = time.replace('12:00pm', '12:00pm (noon)');
    return time;
  }

  public formatTime(hour: string | number, endTime = false): string {
    const adjustedHour = endTime ? Number(hour) + 1 : Number(hour);

    switch (true) {
      case (String(adjustedHour) === '0' || adjustedHour === 24): return `12:00am`;
      case (adjustedHour < 12): return `${String(adjustedHour)}:00am`;
      case (adjustedHour === 12): return `12:00pm`;
      case (adjustedHour > 24): return `${String(adjustedHour - 24)}:00am`;
      default: return `${String(adjustedHour - 12)}:00pm`;
    }
  }

  public formatHHMM(time?: string): string {
    return moment(time ?? this.now(), 'HH:mm').format('h:mm a');
  }

  public holidayDate(year: number, month: number, weekOfMonth: number, dayOfWeek: number, offset: number): string {
    const monthDate = moment({ year: year, month: month - 1 });
    if (weekOfMonth < 9) {
      const first = monthDate.startOf('month').day(dayOfWeek).format('YYYY-MM-DD');
      if (monthDate.month() !== month - 1) {
        weekOfMonth++;
      }
      return this.addDateStr(first, (7 * (weekOfMonth - 1)) + (offset ?? 0));
    } else {
      let last = monthDate.endOf('month');
      while (last.day() !== dayOfWeek) {
        last = moment(this.subtractDate(last.toDate(), 1));
      }
      return this.addDateStr(last.toDate(), (offset ?? 0));
    }
  }

  public includesToday(dateRange: string): boolean {
    const startDate = dateRange.split('~')[0];
    const endDate = dateRange.split('~')[1] || startDate;
    return this.nowStr >= startDate && this.nowStr <= endDate;
  }

  public datesInRange(dateRange: string): string[] {
    const startDate = dateRange.split('~')[0];
    const endDate = dateRange.split('~')[1] || startDate;
    const dates = [];
    for (let date = startDate; date <= endDate; date = this.getNextDate(date)) {
      dates.push(date);
    }
    return dates;
  }

  public parseDates(dates: string[], cutoffDate = this.oldestDateStr): string[] {
    // Default to today if date is not provided
    if (!dates?.length) return [this.nowStr];

    let parsed = [];

    // Support single dates like "2019-02-23" and date ranges like "2019-02-23|2019-03-01"
    if (!dates.toString().includes('|')) {
      parsed = dates;
    } else {
      const parsedDates = dates.map(date => {
        if (!date.includes('|')) {
          return [date];
        } else {
          const startDate = date.split('|')[0];
          const endDate = date.split('|')[1];
          return this.getDatesArray(startDate, endDate);
        }
      });
      // Flatten arrays into single array of dates
      parsed = parsedDates.reduce((a, b) => a.concat(b), []);
    }

    // Remove any duplicates
    const uniqueDates = [...new Set(parsed)];

    // Remove dates outside of licensed range
    const filteredDates = uniqueDates.filter(date => date >= cutoffDate);

    // Sort in reverse order
    return filteredDates.sort().reverse();
  }

  private getDatesArray(startDate: string, endDate: string): string[] {
    if (startDate === 'undefined' || endDate === 'undefined' || endDate < startDate) return [];

    const dates: string[] = [];
    for (let date = startDate; date <= endDate; date = this.getNextDate(date)) {
      dates.push(date);
    }
    return dates;
  }

  private getNextDate(date: string): string {
    const today = this.newDate(date);
    const tomorrow = this.newDate(today.getTime() + (24 * 60 * 60 * 1000));
    return moment.utc(tomorrow).format('YYYY-MM-DD');
  }

  public localTimezone(): string {
    return this.timezoneMap(moment.tz.guess(true));
  }

  public timezoneShortName(timezoneName: string): string {
    return this.timezones?.find(timezone => timezone.value === timezoneName)?.shortName ?? '';
  }

  private timezoneMap(timezone: string): string {
    if (timezone === 'America/St_Johns') return 'America/St_Johns';
    if (timezone === 'America/Glace_Bay') return 'America/Halifax';
    if (timezone === 'America/Goose_Bay') return 'America/Halifax';
    if (timezone === 'America/Halifax') return 'America/Halifax';
    if (timezone === 'America/Moncton') return 'America/Halifax';
    if (timezone === 'America/Detroit') return 'America/New_York';
    if (timezone === 'America/Indiana/Indianapolis') return 'America/New_York';
    if (timezone === 'America/Indiana/Marengo') return 'America/New_York';
    if (timezone === 'America/Indiana/Petersburg') return 'America/New_York';
    if (timezone === 'America/Indiana/Vevay') return 'America/New_York';
    if (timezone === 'America/Indiana/Vincennes') return 'America/New_York';
    if (timezone === 'America/Indiana/Winamac') return 'America/New_York';
    if (timezone === 'America/Kentucky/Louisville') return 'America/New_York';
    if (timezone === 'America/Kentucky/Monticello') return 'America/New_York';
    if (timezone === 'America/New_York') return 'America/New_York';
    if (timezone === 'America/Blanc-Sablon') return 'America/New_York';
    if (timezone === 'America/Indianapolis') return 'America/New_York';
    if (timezone === 'America/Iqaluit') return 'America/New_York';
    if (timezone === 'America/Nipigon') return 'America/New_York';
    if (timezone === 'America/Pangnirtung') return 'America/New_York';
    if (timezone === 'America/Thunder_Bay') return 'America/New_York';
    if (timezone === 'America/Toronto') return 'America/New_York';
    if (timezone === 'America/Chicago') return 'America/Chicago';
    if (timezone === 'America/Indiana/Knox') return 'America/Chicago';
    if (timezone === 'America/Indiana/Tell_City') return 'America/Chicago';
    if (timezone === 'America/Menominee') return 'America/Chicago';
    if (timezone === 'America/North_Dakota/Beulah') return 'America/Chicago';
    if (timezone === 'America/North_Dakota/Center') return 'America/Chicago';
    if (timezone === 'America/North_Dakota/New_Salem') return 'America/Chicago';
    if (timezone === 'America/Atikokan') return 'America/Chicago';
    if (timezone === 'America/Rainy_River') return 'America/Chicago';
    if (timezone === 'America/Rankin_Inlet') return 'America/Chicago';
    if (timezone === 'America/Resolute') return 'America/Chicago';
    if (timezone === 'America/Winnipeg') return 'America/Chicago';
    if (timezone === 'America/Boise') return 'America/Denver';
    if (timezone === 'America/Denver') return 'America/Denver';
    if (timezone === 'America/Cambridge_Bay') return 'America/Denver';
    if (timezone === 'America/Edmonton') return 'America/Denver';
    if (timezone === 'America/Inuvik') return 'America/Denver';
    if (timezone === 'America/Regina') return 'America/Denver';
    if (timezone === 'America/Swift_Current') return 'America/Denver';
    if (timezone === 'America/Yellowknife') return 'America/Denver';
    if (timezone === 'America/Los_Angeles') return 'America/Los_Angeles';
    if (timezone === 'America/Phoenix') return 'America/Phoenix';
    if (timezone === 'America/Creston') return 'America/Los_Angeles';
    if (timezone === 'America/Dawson') return 'America/Los_Angeles';
    if (timezone === 'America/Dawson_Creek') return 'America/Los_Angeles';
    if (timezone === 'America/Fort_Nelson') return 'America/Los_Angeles';
    if (timezone === 'America/Vancouver') return 'America/Los_Angeles';
    if (timezone === 'America/Whitehorse') return 'America/Los_Angeles';
    if (timezone === 'America/Anchorage') return 'America/Anchorage';
    if (timezone === 'America/Juneau') return 'America/Anchorage';
    if (timezone === 'America/Metlakatla') return 'America/Anchorage';
    if (timezone === 'America/Nome') return 'America/Anchorage';
    if (timezone === 'America/Sitka') return 'America/Anchorage';
    if (timezone === 'America/Yakutat') return 'America/Anchorage';
    if (timezone === 'Pacific/Honolulu') return 'Pacific/Honolulu';

    console.error(`APP dt.timezoneMap: Unrecognized time zone ${timezone}`);
    return 'America/New_York';
  }
}
