export class KeyMetrics {
  sales = 0;
  salesRatio = 0;
  orders = 0;
  ordersRatio = 0;
  orderSize = 0;
  productsPerOrder = 0;
  quantity = 0;
  itemsPerOrder = 0;
  profit = 0;
  profitRatio = 0;
  profitMargin = 0;
  discountOrders = 0;
  priceDiscount = 0;
  repeatCustomers = 0;
  regularCustomers = 0;
  tips = 0;
  tippingRate = 0;
  refunds = 0;
  refundedOrders = 0;
  nonRevenue = 0;
  nonRevenueOrders = 0;
  laborCost = 0;
  laborSalesRatio = 0;
  laborPerOrder = 0;
}

export interface KeyMetricsArray {
  sales: number[];
  salesRatio: number[];
  orders: number[];
  ordersRatio: number[];
  orderSize: number[];
  productsPerOrder: number[];
  quantity: number[];
  itemsPerOrder: number[];
  profit: number[];
  profitRatio: number[];
  profitMargin: number[];
  discountOrders: number[];
  priceDiscount: number[];
  repeatCustomers: number[];
  regularCustomers: number[];
  tips: number[];
  tippingRate: number[];
  refunds: number[];
  refundedOrders: number[];
  nonRevenue: number[];
  nonRevenueOrders: number[];
  laborCost: number[];
  laborSalesRatio: number[];
  laborPerOrder: number[];
}

export function initKeyMetricsArray(length: number): KeyMetricsArray {
  return {
    sales: Array(length).fill(0),
    salesRatio: Array(length).fill(0),
    orders: Array(length).fill(0),
    ordersRatio: Array(length).fill(0),
    orderSize: Array(length).fill(0),
    productsPerOrder: Array(length).fill(0),
    quantity: Array(length).fill(0),
    itemsPerOrder: Array(length).fill(0),
    profit: Array(length).fill(0),
    profitRatio: Array(length).fill(0),
    profitMargin: Array(length).fill(0),
    discountOrders: Array(length).fill(0),
    priceDiscount: Array(length).fill(0),
    repeatCustomers: Array(length).fill(0),
    regularCustomers: Array(length).fill(0),
    tips: Array(length).fill(0),
    tippingRate: Array(length).fill(0),
    refunds: Array(length).fill(0),
    refundedOrders: Array(length).fill(0),
    nonRevenue: Array(length).fill(0),
    nonRevenueOrders: Array(length).fill(0),
    laborCost: Array(length).fill(0),
    laborSalesRatio: Array(length).fill(0),
    laborPerOrder: Array(length).fill(0),
  }
}

export class KeyMetricsLabels {
  [key: string]: string;

  sales = '';
  salesRatio = '';
  orders = '';
  ordersRatio = '';
  orderSize = '';
  productsPerOrder = '';
  quantity = '';
  itemsPerOrder = '';
  profit = '';
  profitRatio = '';
  profitMargin = '';
  discountOrders = '';
  priceDiscount = '';
  repeatCustomers = '';
  regularCustomers = '';
  tips = '';
  tippingRate = '';
  refunds = '';
  refundedOrders = '';
  nonRevenue = '';
  nonRevenueOrders = '';
  laborCost = '';
  laborSalesRatio = '';
  laborPerOrder = '';
}

export type KeyMetricsAttr = {
  id: string;
  name: string;
  overrideName?: string;
  shortName?: string;
  overrideShortName?: string;
  format: string;
  shortFormat: string;
  stackable: boolean,
  stackableForProducts: boolean,
  lowIsGood: boolean,
  slideOrder: number;
  uses: string[];
  visible: boolean;
  profitLocationIDs?: string[];
  laborCostLocationIDs?: string[];
  enabled?: boolean;
  required?: boolean;
  plural: boolean;
  stats: string[];
  profitability?: boolean;
  laborCost?: boolean;
  description?: string;
  beta?: boolean;
}

export const allKeyMetricsAttr: KeyMetricsAttr[] = [
  {
    id: 'sales',
    name: 'Sales',
    shortName: 'Sales',
    format: 'currency-2',
    shortFormat: 'currency-0',
    stackable: true,
    stackableForProducts: true,
    lowIsGood: false,
    slideOrder: 0,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    required: true,
    plural: true,
    stats: ['sales'],
    description: `Shows the net revenue from orders (transactions) completed that day, even if the order is paid ` +
      `or fulfilled at a later date.\n\n` +
      `<b>Exclusions:</b>` +
      `<ul>` +
      `<li>Discounts</li>` +
      `<li>Sales taxes</li>` +
      `<li>Tips</li>` +
      `<li>Service charges (by default, but can be included)</li>` +
      `<li>Non-revenue items like gift cards (shown in the ~^nonRevenue~ metric)</li>` +
      `</ul>` +
      `Sales commissions for delivery orders are not deducted by default, but there is an option to do so.`,
  },
  {
    id: 'salesRatio',
    name: '% of Total Sales',
    format: 'percent-1',
    shortFormat: 'percent-0',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: false,
    slideOrder: -1,
    uses: ['trends'],
    visible: true,
    plural: false,
    stats: ['sales'],
  },
  {
    id: 'orders',
    name: 'Orders',
    shortName: 'Orders',
    format: 'number-0',
    shortFormat: 'number-0',
    stackable: true,
    stackableForProducts: false,
    lowIsGood: false,
    slideOrder: 1,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    required: true,
    plural: true,
    stats: ['orders'],
    description: `Counts the number of transactions completed that day, regardless of when payment is received or ` +
      `the order is fulfilled.\n\n` +
      `<b>Exclusions:</b>` +
      `<ul>` +
      `<li>Fully-refunded orders</li>` +
      `<li>Orders that only include non-revenue items, such as a gift card order</li>` +
      `</ul>` +
      `These exclusions ensure the <b>~^orderSize~</b> and <b>~^itemsPerOrder~</b> metrics are accurately ` +
      `calculated.\n\n` +
      `The <b>~^nonRevenueOrders~</b> metric counts transactions that only include non-revenue items.`,
  },
  {
    id: 'ordersRatio',
    name: '% of Total Orders',
    format: 'percent-1',
    shortFormat: 'percent-0',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: false,
    slideOrder: -1,
    uses: ['trends'],
    visible: true,
    plural: false,
    stats: ['orders'],
  },
  {
    id: 'orderSize',
    name: 'Order Size',
    format: 'currency-2',
    shortFormat: 'currency-2',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: false,
    slideOrder: 8,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    plural: false,
    stats: ['sales', 'orders'],
    description:
      `Measures the success of pricing strategies, product bundling, and upselling efforts.\n\n` +
      `This average transaction amount is also known as <b>Ticket Size</b>, <b>Average Spend</b>, ` +
      `<b>Transaction Value</b>, etc., depending on the type of business.\n\n` +
      `When viewed by employee, this metric can measure how effectively each employee is at upselling ` +
      `and cross-selling products.\n\n` +
      `Calculated by dividing <b>~^sales~</b> by <b>~^orders~</b>.`,
  },
  {
    id: 'productsPerOrder',
    name: 'Products Per Order',
    format: 'number-1',
    shortFormat: 'number-1',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: false,
    slideOrder: 9,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    plural: true,
    stats: ['uniqueItems', 'orders'],
    description: `Measures the unique products sold in each order to determine the effectiveness of ` +
      `cross-selling and bundling strategies.\n\n` +
      `When viewed by employee, this metric can measure how effectively each employee is at cross-selling ` +
      `products.\n\n` +
      `Calculated by dividing unique products sold per order by the total number of orders.`,
  },
  {
    id: 'quantity',
    name: 'Items Sold',
    shortName: 'Items',
    format: 'number-0',
    shortFormat: 'number-0',
    stackable: true,
    stackableForProducts: true,
    lowIsGood: false,
    slideOrder: 10,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    required: false,
    plural: true,
    stats: ['quantity'],
    description: `Counts the total quantity of each product sold. For example, an order with 3 cookies and 2 muffins ` +
      `would have an ~^quantity~ value of 5.\n\n` +
      `<b>Exclusions:</b>` +
      `<ul>` +
      `<li>Refunded items</li>` +
      `<li>Non-revenue items, such as gift cards</li>` +
      `</ul>` +
      `These exclusions ensure the <b>~^itemsPerOrder~</b> metric is accurately calculated.`,
  },
  {
    id: 'itemsPerOrder',
    name: 'Items Per Order',
    format: 'number-1',
    shortFormat: 'number-1',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: false,
    slideOrder: 11,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    plural: true,
    stats: ['quantity', 'orders'],
    description: `Measures the effectiveness of volume discounts, product recommendations, and impulse purchases.\n\n` +
      `Along with <b>~^orderSize~</b> and <b>~^productsPerOrder~</b>, this metric can measure price sensitivity to ` +
      `help make better-informed pricing decisions.\n\n` +
      `When viewed by employee, this metric can measure how effectively each employee is at upselling larger ` +
      `quantities of each item.\n\n` +
      `Calculated by dividing <b>~^quantity~</b> by <b>~^orders~</b>.`,
  },
  {
    id: 'profit',
    name: 'Gross Profit',
    format: 'currency-2',
    shortFormat: 'currency-0',
    stackable: true,
    stackableForProducts: true,
    lowIsGood: false,
    slideOrder: 2,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    profitability: true,
    plural: false,
    stats: ['profit'],
    description: `Measures the profit made after deducting the direct costs to produce the products/services ` +
      `from the net revenue.\n\n` +
      `It represents the money available to cover indirect costs, operating expenses, and taxes.\n\n` +
      `The direct costs are defined on the <b>Profitability Analysis</b> page.\n\n` +
      `You can decide how to define your costs for this metric, but the most common approaches align with the ` +
      `definition of <b>Cost of Goods Sold</b> (COGS) or <b>Prime Costs</b> (COGS + direct labor costs).`,
  },
  {
    id: 'profitRatio',
    name: '% of Total Gross Profit',
    format: 'percent-1',
    shortFormat: 'percent-0',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: false,
    slideOrder: -1,
    uses: ['trends'],
    visible: false,
    profitability: true,
    plural: false,
    stats: ['profit'],
  },
  {
    id: 'profitMargin',
    name: 'Gross Profit Margin',
    format: 'percent-1',
    shortFormat: 'percent-1',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: false,
    slideOrder: 3,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    profitability: true,
    plural: false,
    stats: ['profit', 'sales'],
    description: `Measures how efficiently a company manages its production or direct service-related costs.\n\n` +
      `This metric help manage pricing and cost control. It can guide decisions related to cost-cutting and ` +
      `product focus.\n\n` +
      `This metric is most useful when viewed at a high level on the <b>Trends</b> page for the business, one ` +
      `or more locations, and especially at the category or product level.\n\n` +
      `Calculated by dividing <b>~^profit~</b> by <b>~^sales~</b>.`,
  },
  {
    id: 'laborCost',
    name: 'Labor Cost',
    format: 'currency-2',
    shortFormat: 'currency-0',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: true,
    slideOrder: 4,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    laborCost: true,
    plural: false,
    stats: ['laborCost'],
    description: `Calculates the direct labor cost based on the total hours worked (excluding unpaid breaks) ` +
      `and each employee's pay rate.`,
    beta: true,
  },
  {
    id: 'laborSalesRatio',
    name: 'Labor Cost %',
    format: 'percent-1',
    shortFormat: 'percent-1',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: true,
    slideOrder: 5,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    laborCost: true,
    plural: false,
    stats: ['laborCost', 'sales'],
    description: `Measures how efficiently your labor cost is managed relative to net sales.\n\n` +
      `This critical metric can help reduce costs and improve profitability by identifying opportunities to ` +
      `optimize staffing levels and pay rates.\n\n` +
      `Calculated by dividing <b>~^laborCost~</b> by <b>~^sales~</b>.`,
    beta: true,
  },
  {
    id: 'laborPerOrder',
    name: 'Labor Cost per Order',
    format: 'currency-2',
    shortFormat: 'currency-2',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: true,
    slideOrder: 7,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    laborCost: true,
    plural: false,
    stats: ['laborCost', 'orders'],
    description: `Measures the average labor cost per transaction. It is a measure of staff efficiency.\n\n` +
      `Unlike <b>~^laborSalesRatio~</b>, this metric focuses more on operational utilization and efficiency, ` +
      `such as aligning staffing levels with busy times of day.\n\n` +
      `Calculated by dividing <b>~^laborSalesRatio~</b> by <b>~^orders~</b>.`,
    beta: true,
  },
  {
    id: 'discountOrders',
    name: 'Discounted Orders',
    format: 'percent-1',
    shortFormat: 'percent-1',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: true,
    slideOrder: 12,
    uses: ['standard'],
    visible: true,
    plural: true,
    stats: ['discountOrders', 'orders'],
    description: `Shows the percentage of orders that included a discount, which is especially useful when viewed ` +
      `over time.\n\n` +
      `Along with <b>~^priceDiscount~</b>, it measures changes in your discounting strategy, as well as the price ` +
      `sensitivity of your customers.\n\n` +
      `Calculated by dividing the number of discounted orders by the total number of orders.`,
  },
  {
    id: 'priceDiscount',
    name: 'Average Discount',
    format: 'percent-1',
    shortFormat: 'percent-1',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: true,
    slideOrder: 13,
    uses: ['standard'],
    visible: true,
    plural: false,
    stats: ['discounts', 'sales'],
    description: `Shows the average discount applied to each order, which is especially useful when viewed ` +
      `over time.\n\n` +
      `Along with <b>~^discountOrders~</b>, it measures changes in your discounting strategy, as well as the price ` +
      `sensitivity of your customers.\n\n` +
      `Calculated by dividing total discounts by gross sales (sales before discounts).`,
  },
  {
    id: 'repeatCustomers',
    name: 'Repeat Customers',
    format: 'percent-1',
    shortFormat: 'percent-1',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: false,
    slideOrder: 14,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    plural: true,
    stats: ['repeatCustomers', 'trackedCustomers'],
    description: `Of customers uniquely tracked by your point-of-sale system, this metric identifies the ` +
      `percentage of those customers that have visited more than once.\n\n` +
      `This metric is flawed because it ignores customers who pay with cash, and may count a person as two distinct ` +
      `customers if they purchase with different credit cards.\n\n` +
      `Even so, measuring customer loyalty is critical for any business and this metric is very useful despite its ` +
      `limitations.\n\n` +
      `For example, a heatmap graph can show which time of day your most loyal customers visit – and when they don't.`,
  },
  {
    id: 'regularCustomers',
    name: 'Frequent Customers',
    format: 'percent-1',
    shortFormat: 'percent-1',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: false,
    slideOrder: 15,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    plural: true,
    stats: ['regularCustomers', 'trackedCustomers'],
    description: `This is the same as the <b>~^repeatCustomers~</b> metric, except it identifies customers that ` +
      `have purchased <b>10</b> or more times (rather than <b>2</b> or more).\n\n` +
      `Measuring your most loyal customers can provide deep insights into your business and help identify ways to ` +
      `turn more visitors into loyal customers.`,
  },
  {
    id: 'tips',
    name: 'Non-Cash Tips',
    format: 'currency-2',
    shortFormat: 'currency-0',
    stackable: true,
    stackableForProducts: true,
    lowIsGood: false,
    slideOrder: 16,
    uses: ['standard'],
    visible: true,
    plural: true,
    stats: ['tips'],
    description: `Reflects tips collected by your point-of-sale system, which excludes cash tips.\n\n` +
      `By itself, this metric is not overly useful for businesses that accept cash tips. However, ` +
      `<b>~^tippingRate~</b> builds on this metric to provide a reasonably good measure of your customers' ` +
      `tipping behavior.`,
  },
  {
    id: 'tippingRate',
    name: 'Tipping Rate',
    format: 'percent-1',
    shortFormat: 'percent-1',
    stackable: false,
    stackableForProducts: false,
    lowIsGood: false,
    slideOrder: 17,
    uses: ['standard'],
    visible: true,
    plural: false,
    stats: ['tips', 'tippableSales'],
    description:
      `Measures non-cash tips as a percentage of non-cash sales.\n\n` +
      `To determine if this is a useful metric for your business, see if it correlates with the ratio of cash tips ` +
      `to cash sales. If not, you can hide the tipping metrics in <b>Preferences</b>.\n\n` +
      `This metric provides a way to measure the quality of service over time, such as whether a training program ` +
      `leads to better service.\n\n` +
      `On the Trends page, you can even compare <b>~^tippingRate~</b> by employee to identify your team's best and ` +
      `worst performers based on this form of customer feedback.\n\n` +
      `Calculated by dividing <b>~^tips~</b> by non-cash sales (i.e., orders placed using ` +
      `credit cards, gift cards, Apple Pay, etc., where the customer can add a tip).`,
  },
  {
    id: 'refunds',
    name: 'Refunds',
    format: 'currency-2',
    shortFormat: 'currency-0',
    stackable: true,
    stackableForProducts: true,
    lowIsGood: true,
    slideOrder: 18,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    plural: true,
    stats: ['refunds'],
    description: `Shows the net revenue of transactions completed that day and then fully refunded on that day or ` +
      `a later date.\n\n` +
      `This approach ensures <b>~^sales~</b> are accurately reflected on the day of the original transaction.\n\n` +
      `This is different from standard accounting practices, but is designed to make better-informed decisions ` +
      `because it accurately reflects revenue for both the original date and the refund date (if different).`,
  },
  {
    id: 'refundedOrders',
    name: 'Refunded Orders',
    format: 'number-0',
    shortFormat: 'number-0',
    stackable: true,
    stackableForProducts: false,
    lowIsGood: true,
    slideOrder: 19,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    plural: true,
    stats: ['refundedOrders'],
    description: `Counts the number of transactions completed that day and then fully refunded on that day or ` +
      `a later date.\n\n` +
      `This approach ensures metrics like <b>~^orderSize~</b> and <b>~^itemsPerOrder~</b> are accurate for both ` +
      `the original date and the refund date (if different).`,
  },
  {
    id: 'nonRevenue',
    name: 'Non-Revenue Sales',
    format: 'currency-2',
    shortFormat: 'currency-0',
    stackable: true,
    stackableForProducts: true,
    lowIsGood: false,
    slideOrder: 20,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    plural: true,
    stats: ['nonRevenue'],
    description: `Shows the amount of non-revenue transactions, such as gift cards, store credits, or donations.\n\n` +
      `This metric provides a way to identify unusual changes these types of orders.`,
  },
  {
    id: 'nonRevenueOrders',
    name: 'Non-Revenue Orders',
    format: 'number-0',
    shortFormat: 'number-0',
    stackable: true,
    stackableForProducts: false,
    lowIsGood: false,
    slideOrder: 21,
    uses: ['standard', 'heatmap', 'trends'],
    visible: true,
    plural: true,
    stats: ['nonRevenueOrders'],
    description: `Counts the number of transactions consisting of items that are not considered revenue, such as ` +
      `gift cards, store credits, or donations.\n\n`,
  },
];
