import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import {
  AuthError, browserLocalPersistence, browserSessionPersistence, createUserWithEmailAndPassword, FacebookAuthProvider,
  fetchSignInMethodsForEmail, getAdditionalUserInfo, getAuth, getRedirectResult, GoogleAuthProvider, IdTokenResult,
  onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithRedirect, signOut, User,
} from 'firebase/auth';

import { setUser } from '@sentry/browser';

import CryptoES from 'crypto-es';
import { nanoid } from 'nanoid';

import { AfsService } from '@services/afs.service';
import { AlertService } from '@services/alert.service';
import { ChannelsService } from '@services/channels.service';
import { ConnectService } from '@services/connect.service';
import { DtService } from '@services/dt.service';
import { EnvService } from '@services/env.service';
import { ErrorService } from '@services/error.service';
import { FunctionsService } from '@services/functions.service';
import { LsService } from '@services/ls.service';
import { NavigateService } from '@services/navigate.service';
import { OtpService } from '@services/otp.service';
import { SettingsService } from '@settings/settings.service';
import { SignoutService } from '@services/signout.service';
import { SourcesService } from '@services/sources.service';
import { UtilitiesService } from '@services/utilities.service';

import { ClientSettings, CLIENT_SETTINGS_VERSION } from '@shared/settings.interface';
import { RequestAddConnection } from '@shared/request.interface';
import { PendingUser, UserSettings } from '@shared/user.interface';

import { AppState } from '@state/app.state';
import { UserState } from '@state/user.state';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  private auth = getAuth();

  constructor(
    private afs: AfsService,
    private alert: AlertService,
    private appState: AppState,
    private channels: ChannelsService,
    private connect: ConnectService,
    private dt: DtService,
    private env: EnvService,
    private error: ErrorService,
    private functions: FunctionsService,
    private ls: LsService,
    private navigate: NavigateService,
    private otp: OtpService,
    private router: Router,
    private settings: SettingsService,
    private signout: SignoutService,
    private sources: SourcesService,
    private userState: UserState,
    private util: UtilitiesService,
  ) { }

  ngOnDestroy(): void {
    this.signout.complete();
  }

  public async initialize(): Promise<void> {
    // Always force signin if user chose that option in Preferences
    const persistence = this.userState.getPref('authPersistence', 'local') as string;
    if (persistence === 'session') await this.updateAuthPersistence(persistence);

    return new Promise(resolve => {
      onAuthStateChanged(this.auth, async user => {
        if (user) {
          setUser({ 'id': user.uid, 'email': user.email ?? '', 'username': user.displayName ?? '' });  // For Sentry

          try {
            const clientID = await this.getClientIDFromClaims(user, false);

            if (clientID) {
              this.userState.setUser(user, clientID);
              await this.userState.initSettings(clientID);

              await this.updateAccount(user, clientID);
            }

            const encryptedParams = this.ls.get('redirect', '');
            if (encryptedParams) {
              await this.handleRedirectParams(user, encryptedParams);
            }
          } catch (error) {
            console.error(`Error during onAuthStateChanged for ${user?.uid}: ${this.error.toStr(error)}`);
          } finally {
            this.ls.set('redirect', '');
          }
        }

        resolve();
      });
    });
  }

  private async handleRedirectParams(user: User, encryptedParams: string): Promise<void> {
    await this.alert.loadingMessageAwait('Signing in...', 30);

    const decrypted = CryptoES.AES.decrypt(encryptedParams, 'e9bbab78').toString(CryptoES.enc.Utf8);
    const sourceID = decrypted.split('|')[0];
    const merchantID = decrypted.split('|')[1];
    const merchantCode = decrypted.split('|')[2];

    const userCredential = await getRedirectResult(this.auth)
      .catch(error => console.error(`getRedirectResult failed for ${user?.uid}/${user?.email}: ${error}`));

    if (!userCredential) {
      console.error(`getRedirectResult failed for ${user?.uid}/${user?.email} with ${encryptedParams}`);
      return;
    }

    const isNewUser = getAdditionalUserInfo(userCredential)?.isNewUser ?? true;

    if (!isNewUser) {
      if (sourceID && merchantID && merchantCode) {
        await this.addConnection(sourceID, this.appState.clientID, '', '', merchantID, merchantCode);
      }
      await this.routeNext(this.userState.user.emailVerified ?? false);
      return;
    }

    // New Google/Facebook accounts must have an email address
    if (!userCredential.user?.email) {
      const source = decrypted.split('|')[3] ?? 'Google/Facebook';
      const otherSource = source === 'Facebook' ? 'Google' : source === 'Google' ? 'Facebook' : 'different';
      await this.alert.confirm('Email Address Required',
        `No email address is associated with this ${source} account.\n\nPlease use a ${otherSource} account ` +
        `or enter an email address and password.`, 'error', 'Try Again');
      this.ls.set('defaultSignin', '0');
      await this.router.navigate(['signin'], { queryParams: { page: 'register' } });
      return;
    }

    await this.createAccount(user);
    await this.sendEmailVerification(user);

    await this.routeNext(this.userState.user.emailVerified ?? false);
  }

  private async getClientIDFromClaims(user: User, retry = true): Promise<string> {
    if (!user) return '';

    let tokenResult = await user.getIdTokenResult()
      .catch(error => {
        console.error(`getClientIDFromClaims failed for ${user.uid}: ${error}`);
        if (error?.includes('auth/network-request-failed')) {
          retry = true;
        }
      });
    let clientID = ((tokenResult as IdTokenResult)?.claims?.clientID as string) ?? '';

    if (!clientID && retry) {
      for (let attempt = 0; attempt < 3; attempt++) {
        // Retry after forcing a token refresh
        tokenResult = await user.getIdTokenResult(true)
          .catch(error => console.error(`getClientIDFromClaims failed after refresh for ${user.uid}: ${error}`));
        clientID = ((tokenResult as IdTokenResult)?.claims?.clientID as string) ?? '';
        if (clientID) break;
        console.error(`getClientIDFromClaims retry ${attempt + 1} needed for ${user.uid}`);
        await this.util.sleep(1);
      }
    }

    return clientID;
  }

  private async refreshCustomClaims(user: User): Promise<void> {
    await user?.getIdTokenResult(true)
      .catch(error => console.error(`refreshCustomClaims failed for ${user?.uid}: ${error}`));
  }

  // ----------------------
  // ACCOUNT FUNCTIONS
  // ----------------------

  private async createAccount(user: User, sourceID = '??', merchantID?: string, merchantCode?: string): Promise<void> {
    await this.alert.loadingMessageAwait('Creating account...', 2, 30);

    const clientID = nanoid(9);
    const locationID = nanoid(9);
    const connectionID = nanoid(9);

    // Create user; reuse existing account for pending users
    const { pendingUser, pendingUserID } = await this.getPendingUser(user);
    if (pendingUser && pendingUserID) {
      await this.createUser(user, pendingUser.clientID, pendingUser);
      await this.updateAccount(user, pendingUser.clientID);
      await this.afs.deleteDocument('pendingUsers', pendingUserID);
      return;
    } else {
      await this.createUser(user, clientID);
    }

    // Create new business/location/connection records
    const channels = [];
    const locationChannels = [];
    sourceID = sourceID || '??';
    if (sourceID !== '??') {
      const channelIDs = this.sources.getSetting(sourceID, 'channelIDs') as string[];
      for (const channelID of channelIDs) {
        channels.push({
          channelID: channelID,
          color: this.channels.setColor(channelID),
        });
        locationChannels.push({
          channelID: channelID,
          servchgs: false,
          commission: 0,
        });
      }
    }

    const newBusiness: ClientSettings = {
      _version: CLIENT_SETTINGS_VERSION,
      _connectionMaps: sourceID && merchantID && merchantCode ? [`${sourceID}|${merchantID}`] : [],
      _timezones: [],
      businessName: '',
      startDayOfWeek: 1,
      locations: [],
      channels: channels,
      license: {
        billingAgent: ['CL', 'SH'].includes(sourceID) ? sourceID : '',
        licenseID: ['CL', 'SH'].includes(sourceID) && merchantID ? merchantID : 'UNLICENSED',
        status: 'inactive',
      },
    };

    newBusiness.locations[0] = {
      locationID: locationID,
      active: true,
      timezone: '',
      dayEndHour: 0,
      connections: [],
      channels: locationChannels,
    };

    newBusiness.locations[0].connections = [{
      connectionID: connectionID,
      sourceID: sourceID,
      active: !!(merchantID && merchantCode),
      id: merchantID ?? '',
      token: merchantCode ?? '',
      lastChecked: this.dt.oldestDate,
    }];

    // Create client record in Firestore
    try {
      await this.afs.setDocument('clients', clientID, newBusiness, { merge: false });
    } catch (error) {
      console.error(`APP Auth error updating clients: ${clientID}, ${this.error.toStr(error)}, ` +
        `${JSON.stringify(newBusiness)}`);
    }

    // Update account record and get settings
    await this.updateAccount(user, clientID);

    // Request back-end to finish Clover/Shopify setup and request data
    if (sourceID === 'SH' && merchantID && !merchantCode) {
      await this.connect.addShopifyConnection(merchantID);
    } else if (sourceID && merchantID && merchantCode) {
      await this.addConnection(sourceID, clientID, locationID, connectionID, merchantID, merchantCode);
    }

    this.alert.loadingMessage();
  }

  private async createUser(user: User, clientID: string, pendingUser?: PendingUser): Promise<void> {
    try {
      const userSettings: UserSettings = {
        uid: user.uid,
        clientID: pendingUser?.clientID ?? clientID,
        name: user.displayName ?? '',
        email: user.email,
        permissions: pendingUser?.permissions ?? [],
        locationIDs: pendingUser?.locationIDs ?? [],
        firstUse: '',
        uses: 0,
        latestUse: '',
        latestVersion: '',
        betaUser: false,
        parentIDs: pendingUser?.parentIDs ?? [],
      };
      await this.functions.updateUser(user.uid, userSettings);
      await this.refreshCustomClaims(user);
      this.userState.setUser(user, userSettings.clientID);
      await this.userState.initSettings(userSettings.clientID);

      if (!user.email?.startsWith('sroth720')) {
        console.error(`New user created for ${user.email}, ${clientID}, ${user.uid}`);
      }
    } catch (error) {
      console.error(`Error creating user: ${user.uid}, ${this.error.toStr(error)}`);
    }
  }

  private async getPendingUser(user: User): Promise<{ pendingUser: PendingUser | undefined, pendingUserID: string }> {
    if (!user.email) return { pendingUser: undefined, pendingUserID: '' };
    return await this.afs.queryPendingUser(user.email);
  }

  private async updateAccount(user: User, clientID?: string): Promise<void> {
    if (!clientID) {
      clientID = await this.getClientIDFromClaims(user);
    }

    if (clientID) {
      this.appState.clientID = clientID;
      this.settings.getClientSettings$(clientID);
    }
  }

  private async addConnection(sourceID: string, clientID: string, locationID: string, connectionID: string,
    merchantID: string, merchantCode: string): Promise<void> {

    const updateOnly = !!merchantCode && !locationID && !connectionID;

    if (!locationID || !connectionID) {
      const settings = await this.afs.getDocument<ClientSettings>('clients', clientID);
      if (settings) {
        for (const location of settings.locations) {
          for (const connection of location.connections) {
            if (connection.id === merchantID) {
              locationID = location.locationID;
              connectionID = connection.connectionID;
              break;
            }
          }
        }
      }
      if (!locationID || !connectionID) {
        console.error(`APP auth.addConnection: Unable to find locationID or connectionID for ${merchantID}`);
        return;
      }
    }

    await this.alert.loadingMessageAwait(`Creating connection to ${this.sources.getSetting(sourceID, 'name')}...`, 3, 30);
    if (!merchantCode) {
      console.error(`Missing merchant code in auth.addConnection for ${clientID}|${locationID}`);
      this.alert.loadingMessage();
      return;
    }

    const message: RequestAddConnection = {
      hostname: this.env.databaseURL,
      sourceID, clientID, locationID, connectionID, merchantID, merchantCode, updateOnly,
    };
    await this.functions.addConnection(message);
    this.alert.loadingMessage();
  }

  // ----------------------
  // REGISTRATION FUNCTIONS
  // ----------------------

  public async registerWithEmail(email: string, password: string, sourceID?: string, merchantID?: string,
    merchantCode?: string): Promise<void> {

    try {
      const userCredential = await createUserWithEmailAndPassword(this.auth, email, password);
      const user = userCredential.user;
      if (user) {
        await this.createAccount(user, sourceID, merchantID, merchantCode);
        await this.sendEmailVerification(user);
        await this.routeNext(user.emailVerified);
      }
    } catch (error) {
      if ((error as AuthError).code === 'auth/email-already-in-use') {
        await this.signinWithEmail(email, password, sourceID, merchantID, merchantCode, true);
      } else {
        await this.handleAuthError(error as AuthError, 'register');
      }
    }
  }

  // ----------------------
  // SIGNIN FUNCTIONS
  // ----------------------

  public async signinWithProvider(provider: string, sourceID: string, merchantID: string, merchantCode: string,
    email?: string): Promise<void> {

    await this.alert.loadingMessageAwait(`Connecting to ${provider}...`, 30);
    try {
      const authProvider = provider === 'Google' ? new GoogleAuthProvider() : new FacebookAuthProvider();
      if (email) authProvider.setCustomParameters({ login_hint: email });
      const params = `${sourceID}|${merchantID}|${merchantCode}|${provider}`;
      const encryptedParams = CryptoES.AES.encrypt(params, 'e9bbab78').toString();
      this.ls.set('redirect', encryptedParams);
      await signInWithRedirect(this.auth, authProvider);
    } catch (error) {
      await this.handleAuthError(error as AuthError, 'signin');
    } finally {
      this.alert.loadingMessage();
    }
  }

  public async signinWithEmail(email: string, password: string, sourceID?: string, merchantID?: string,
    merchantCode?: string, registerFailed?: boolean): Promise<void> {

    try {
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
      if (userCredential.user) {
        if (getAdditionalUserInfo(userCredential)?.isNewUser) {
          await this.createAccount(userCredential.user, sourceID, merchantID, merchantCode);
        } else {
          await this.updateAccount(userCredential.user);
          if (sourceID === 'SH' && merchantID && !merchantCode) {
            await this.connect.addShopifyConnection(merchantID);
          } else if (sourceID && merchantID && merchantCode) {
            await this.addConnection(sourceID, this.appState.clientID, '', '', merchantID, merchantCode);
          }
        }
        await this.routeNext(userCredential.user.emailVerified);
      }
    } catch (error) {
      if (['auth/user-not-found', 'auth/invalid-login-credentials', 'auth/invalid-credential']
        .includes((error as AuthError).code)) {
        this.ls.set('defaultSignin', '0');
        const response = await this.alert.stayOrRoute('Account Not Found',
          'No account was found for these credentials. Please make sure you entered them correctly.\n\n' +
          'Tap the button below to create a new account with these credentials.',
          'error', 'Try Again', 'Create Account');
        if (response.isConfirmed) {
          await this.registerWithEmail(email, password, sourceID, merchantID, merchantCode);
        }
      } else {
        const provider = await fetchSignInMethodsForEmail(this.auth, email);
        await this.handleAuthError(error as AuthError, registerFailed ? 'register' :
          'signin', email, provider, sourceID, merchantID, merchantCode);
      }
    }
  }

  private async routeNext(emailVerified: boolean): Promise<void> {
    if (this.appState.clientID && this.getUser()) {
      this.ls.set('defaultSignin', '1');
      if (!emailVerified) {
        await this.router.navigate(['verify-email']);
      } else {
        await this.alert.loadingMessageAwait('Signing in...', 30);
        await this.navigate.home();
      }
    } else {
      await this.router.navigate(['settings']);
    }
  }

  public async getSigninMethod(email: string): Promise<string[]> {
    return await fetchSignInMethodsForEmail(this.auth, email);
  }

  public async resetPassword(email: string): Promise<string> {
    try {
      await sendPasswordResetEmail(this.auth, email);
      return '';
    } catch (error) {
      return (error as AuthError).code;
    }
  }

  public async updateAuthPersistence(persistence: string): Promise<void> {
    if (persistence === 'session') {
      await this.auth.setPersistence(browserSessionPersistence);
    } else {
      await this.auth.setPersistence(browserLocalPersistence);
    }
  }

  private async sendEmailVerification(user?: User | null): Promise<void> {
    user = user ?? this.auth.currentUser;
    if (!user?.email) {
      throw new Error(`APP auth.sendEmailVerification: User email address not defined for ${JSON.stringify(user)}`);
    }

    await this.alert.loadingMessageAwait('Sending verification passcode via email...', 3, 30);
    const passcode = await this.otp.getPasscode(user.email);
    await this.otp.emailPasscode(user.email, passcode);
  }

  // ----------------------
  // MISC PUBLIC FUNCTIONS
  // ----------------------

  public getUser(): User {
    return this.auth.currentUser;
  }

  public async reloadUser(): Promise<void> {
    const user = this.auth.currentUser;
    if (user) await user.reload();
  }

  public async signOut(): Promise<void> {
    this.alert.loadingMessage();  // Dismiss message overlay

    // Unsubscribe from all observables
    this.signout.next();
    this.signout.complete();
    this.appState.resetState();
    this.userState.resetState();

    // Signout from Firebase Auth
    await this.userSignout();

    // Reload to clear everything and return to Signin page
    window.location.reload();
  }

  public async userSignout(): Promise<void> {
    await signOut(this.auth);
  }

  // ----------------------
  // INTERNAL FUNCTIONS
  // ----------------------

  private async handleAuthError(error: AuthError, type: string, email?: string,
    provider?: string[], sourceID?: string, merchantID?: string, merchantToken?: string): Promise<void> {

    this.alert.loadingMessage();
    switch (error.code) {
      case 'auth/wrong-password': {
        if (provider?.[0] === 'facebook.com') {
          const response = await this.alert.stayOrRoute('Account Already Exists',
            `A Facebook account already exists for email address <b>${email}</b>.\n\n` +
            `Sign in using Facebook to access this existing account, or ` +
            `use a different email address to create a new account.`,
            'info', 'Try Again', 'Sign In Using Facebook');
          if (response.isConfirmed) {
            await this.signinWithProvider('Facebook', sourceID ?? '', merchantID ?? '', merchantToken ?? '', email);
          }
        } else if (provider?.[0] === 'google.com') {
          const response = await this.alert.stayOrRoute('Account Already Exists',
            `A Google account already exists for email address <b>${email}</b>.\n\n` +
            `Sign in using Google to access this existing account, or ` +
            `use a different email address to create a new account.`,
            'info', 'Try Again', 'Sign In Using Google');
          if (response.isConfirmed) {
            await this.signinWithProvider('Google', sourceID ?? '', merchantID ?? '', merchantToken ?? '', email);
          }
        } else if (type === 'register') {
          await this.alert.message('Account Already Exists',
            `An account already exists for email address ${email}.\n\nSign in with the correct password or ` +
            `use another email address to create an account.`,
            'error', 'Try Again');
        } else {
          await this.alert.message('Incorrect Password',
            'The password is incorrect. Please make sure you entered it correctly.',
            'error', 'Try Again');
        }
        break;
      }
      case 'auth/email-already-in-use': {
        await this.alert.TryAgainOrSignin('Account Already Exists',
          'An account already exists for this email address.\n\nTry using a different email address, or tap ' +
          'the button below to sign in to this existing account.',
          'error', 'Try Again', 'Sign In');
        break;
      }
      case 'auth/account-exists-with-different-credential': {
        await this.alert.message('Account Already Exists',
          `An account already exists for email address <b>${error.customData?.email}</b>.\n\n` +
          `Sign in with this email address instead of signing in using Facebook.`,
          'error', 'Try Again',
        );
        break;
      }
      default: {
        console.error('Signin error', this.error.toStr(error), error?.code);
        await this.alert.message('Signup/Signin Error',
          'An unexpected problem occurred while signing up.',
          'error', 'Try Again',
        );
        break;
      }
    }
  }
}
