import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as Sentry from '@sentry/browser';

import { AlertService } from '@services/alert.service';
import { EnvService } from '@services/env.service';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  errorStrings: string[] = [
    `auth/`,
    `Cannot read properties of undefined (reading 'isProxied')`,
    `Cannot read properties of undefined (reading 'navigationStart')`,
    `Cannot read properties of undefined (reading 'progressEnd')`,
    `Cannot read properties of undefined (reading 'shadowRoot')`,
    `Cannot read property 'isProxied' of undefined`,
    `Cannot read property 'progressEnd' of undefined`,
    `Failed to execute 'getValue' on 'CookieDeprecationLabel': Illegal invocation`,
    `Failed to obtain primary lease`,
    `performance.now is not a function`,
    `popup-closed-by-user`,
    `The popup has been closed by the user before finalizing the operation`,
    `The project's quota for this operation has been exceeded.`,
    `This operation has been cancelled due to another conflicting popup being opened`,
  ];

  reloadErrors: string[] = [
    `ASSERT: can not be animating`,
    `Canvas is already in use`,
    `ChunkLoadError`,
    `Failed to fetch`,
    `INTERNAL ASSERTION FAILED: Unexpected state`,
    `isProxied`,
    `Unexpected token '<'`,
    `Unhandled Promise rejection: Load failed`,
    `Unhandled Promise rejection: Loading chunk`,
    `undefined is not an object (evaluating 'f.data[0].x')`,
    `auth/network-request-failed`,
    `Connection to Indexed Database server lost. Refresh the page to try again`,
  ];

  ignoreErrors: string[] = [
    `(auth/`,
    `A network error`,
    `AbortError: AbortError`,
    `Cannot read properties of undefined (reading 'isProxied')`,
    `Cannot read properties of undefined (reading 'progressEnd')`,
    `Cannot read properties of undefined (reading 'shadowRoot')`,
    `Cannot read property 'progressEnd' of undefined`,
    `Could not reach Cloud Firestore backend`,
    `Detected an update time that is in the future`,
    `Failed to obtain exclusive access to the persistence layer`,
    `Failed to obtain primary lease`,
    `FirebaseError: deadline-exceeded`,
    `FirebaseError: internal`,
    `installations/app-offline`,
    `Network Error`,
    `ResizeObserver loop`,
    `The client has already been terminated.`,
    `TypeError: Load failed`,
    `Uncaught (in promise): EmptyError: no elements in sequence`,
    `Invalid call to runtime.sendMessage(). Tab not found.`,
    `#undefined" was not found`,
    `Service worker registration failed with: Error: Rejected`,
  ];

  ignoreErrorsProd: string[] = [
    `Error: Uncaught (in promise): FirebaseError: internal`,
    `INTERNAL ASSERTION FAILED: Unexpected state`,
    `Missing or insufficient permissions.`,
    `The project's quota for this operation has been exceeded.`,
  ];

  constructor(
    private alert: AlertService,
    private env: EnvService,
    private router: Router,
  ) { }

  public toIgnore(): string[] {
    return this.errorStrings
      .concat(this.reloadErrors)
      .concat(this.ignoreErrors)
      .concat(this.env.prod ? this.ignoreErrorsProd : []);
  }

  public async handleError(error: Error): Promise<void> {
    // Hide an open dialogs
    this.alert.loadingMessage();
    this.alert.simpleMessageClose();

    if (navigator.onLine) {
      const errorMessage = this.toStr(error);
      const errorWithStack = this.toStr(error, true);

      for (const error of this.reloadErrors) {
        if (errorMessage.includes(error)) {
          console.warn(`%cHandled error with reload: ${errorWithStack}`, 'background: lightgreen');
          window.location.reload();
          return;
        }
      }

      for (const error of this.ignoreErrors) {
        if (errorMessage.includes(error)) {
          console.warn(`%cIgnored error: ${errorWithStack}`, 'background: lightgreen');
          return;
        }
      }

      if (this.env.prod) {
        for (const error of this.ignoreErrorsProd) {
          if (errorMessage.includes(error)) {
            console.warn(`%cIgnored error in prod: ${errorWithStack}`, 'background: lightgreen');
            return;
          }
        }
      }

      // For all of the remaining errors, call Sentry and show the Error page
      if (this.env.useSentry) {
        Sentry.captureException(this.extractError(error));
      } else {
        console.error(`%cUnhandled error: ${errorWithStack}`, 'background: orange');
      }

      // Create error alert and show error page to user
      await this.router.navigate(['error'], { queryParams: { error: errorWithStack } });
    }
  }

  private extractError(error: any): any {  // eslint-disable-line
    if (error?.ngOriginalError) error = error.ngOriginalError;
    if (typeof error === 'string' || error instanceof Error) return error;
    return 'Unknown error';
  }

  public toStr(error: any, includeStack = false): string {  // eslint-disable-line
    error = error.ngOriginalError ?? error;
    if (error instanceof Error) {
      const base = `${error.name}: ${error.message}`;
      return includeStack && error.stack ? `${base}, ${error.stack}` : base;
    } else if (error instanceof ErrorEvent) {
      return `${error.message} at ${error.filename}:${error.lineno}:${error.colno}`;
    } else if (typeof error === 'string') {
      return error;
    } else if (typeof error === 'object' && error !== null) {
      return JSON.stringify(error, null, 2);
    } else {
      return 'An unknown error occurred';
    }
  }

  public message(clientID: string, locationID: string, module: string, message: string): void {
    console.error(`${clientID}${locationID ? '/' : ''}${locationID}${clientID ? ': ' : ''}${module}: ${message}`);
  }

  public warn(clientID: string, locationID: string, module: string, message: string): void {
    console.warn(`${clientID}${locationID ? '/' : ''}${locationID}${clientID ? ': ' : ''}${module}: ${message}`);
  }

}
