import { Injectable } from '@angular/core';

import { DtService } from '@services/dt.service';

@Injectable({ providedIn: 'root' })
export class FmtService {

  private currencyFormatCache: { [key: string]: Intl.NumberFormat } = {};
  private percentFormatCache: { [key: string]: Intl.NumberFormat } = {};
  private numberFormatCache: { [key: string]: Intl.NumberFormat } = {};

  private dateCache: Map<string, string> = new Map();

  private static readonly DOUBLE_TILDE_REGEX = /~~/g;

  constructor(
    private dt: DtService,
  ) { }

  // NUMBER FORMATTING FUNCTIONS

  public format(format: string, value: number): string {
    const decimals = Number(format.slice(-1)) || 0;
    if (format.includes('currency')) return this.currency(value, decimals);
    if (format.includes('percent')) return this.percent(value, decimals);
    if (format.includes('number')) return this.number(value, decimals);
    console.error(`APP fmt.format: Unknown format ${format}`);
    return '';
  }

  public currency(number: number, decimals = 2): string {
    if (isNaN(number)) number = 0;
    return this.getCurrencyFormatter(decimals).format(number);
  }

  public percent(number: number, decimals = 0, showPlus = false): string {
    if (isNaN(number)) number = 0;
    if (!isFinite(number)) return 'N/A';
    return this.getPercentFormatter(decimals, showPlus).format(number);
  }

  public number(number: number, decimals = 0): string {
    if (isNaN(number)) number = 0;
    return this.getNumberFormatter(decimals).format(number);
  }

  // DATE FORMATTING FUNCTIONS

  public dateWithYear(date: string, days: number, short = false): string {
    return `${this.dt.format(date, days <= 365 ? (short ? 'MMM D' : 'MMMM D') : 'MMM D, YYYY')}`;
  }

  public dateRange(start: string, end = '', longMonth = false, showYear = true, numbersOnly = false): string {
    const MMMD = numbersOnly && end ? 'M/D' : longMonth ? 'MMMM D' : 'MMM D';
    const YYYY = !showYear ? '' : numbersOnly && end ? '/YY' : ', YYYY';

    const oneYear = start.substring(0, 4) === end.substring(0, 4) &&
      start === this.dt.startOf(start, 'year') && end === this.dt.endOf(end, 'year');
    if (oneYear) return `Jan to Dec ${this.dt.format(start, 'YYYY')}`;

    const oneMonth = this.dt.getMonth(start) === this.dt.getMonth(end) &&
      start === this.dt.startOf(start, 'month') && end === this.dt.endOf(end, 'month');
    if (oneMonth) return this.dt.format(start, `MMMM${showYear ? ' YYYY' : ''}`);

    if (start === end || !end) return this.dt.format(start, `${!numbersOnly ? 'ddd, ' : ''}${MMMD}${YYYY}`);

    const sameYear = start.substring(0, 4) === end.substring(0, 4);
    const sameMonth = start.substring(5, 7) === end.substring(5, 7);

    if (sameYear && sameMonth) {
      return `${this.dt.format(start, MMMD)}-${this.dt.format(end, `D${YYYY}`)}`;
    } else if (sameYear) {
      return `${this.dt.format(start, MMMD)}${numbersOnly ? ' - ' : ' to '}${this.dt.format(end, `${MMMD}${YYYY}`)}`;
    } else {
      return `${this.dt.format(start, `${MMMD}${YYYY}`)}${numbersOnly ? '-' : ' to '}${this.dt.format(end, `${MMMD}${YYYY}`)}`;
    }
  }

  public hour(hour: number, minute?: number, compress = false): string {
    const minuteStr = minute ? ':' + (minute < 10 ? '0' : '') + String(minute) : '';
    const amSuffix = `${minuteStr}${compress ? '' : ' '}am`;
    const pmSuffix = `${minuteStr}${compress ? '' : ' '}pm`;
    switch (true) {
      case (hour === 0 || hour === 24): return `12${amSuffix}`;
      case (hour < 12): return `${String(hour)}${amSuffix}`;
      case (hour === 12): return `12${pmSuffix}`;
      case (hour > 24): return `${String(hour - 24)}${amSuffix}`;
      default: return `${String(hour - 12)}${pmSuffix}`;
    }
  }

  public hourRange(startHour: number, startMinute: number, endHour: number, endMinute: number,
    compress = false): string {

    const am = compress ? 'am' : ' am';
    const pm = compress ? 'pm' : ' pm';

    const startHourStr = this.hour(startHour, startMinute, true);
    const endHourStr = this.hour(endHour, endMinute, true);
    if (startHourStr.includes(am) && endHourStr.includes(am)) {
      return `${startHourStr.replace(am, '')}-${endHourStr}`;
    } else if (startHourStr.includes(am) && endHourStr.includes(pm)) {
      return `${startHourStr.replace(pm, '')}-${endHourStr}`;
    } else {
      return `${startHourStr}${compress ? '' : ' '}-${compress ? '' : ' '}${endHourStr}`;
    }
  }

  // STRING FORMATTING FUNCTIONS

  public lowerCase(str: string): string {
    // Ignore acronyms when converting to lowercase (Customer LTV -> customer LTV)
    return str.replace(/\b[A-Z]+(?:\b|(?=\s))/g, match => match)
      .replace(/[A-Z][a-z]+/g, match => match.toLowerCase());
  }

  public singular(str: string): string {
    const pluralRules = [
      { regex: /ies$/, replacement: 'y' },
      { regex: /ves$/, replacement: 'f' },
      { regex: /s$/, replacement: '' },
    ];

    const words = str.split(' ');

    // Attempt to singularize the last word first
    for (let i = words.length - 1; i >= 0; i--) {
      const word = words[i];

      // Apply plural rules
      for (const rule of pluralRules) {
        if (rule.regex.test(word)) {
          words[i] = word.replace(rule.regex, rule.replacement);
          break;
        }
      }

      // Stop if we successfully singularize the last word
      if (words[i] !== word) break;
    }

    return words.join(' ');
  }

  // PACK FUNCTIONS

  public packBool(flag: boolean): string {
    return flag ? '1' : '0';
  }

  public packDate(date: string): string {
    // Remove YY from YYYY
    return this.dt.format(date, 'YYMMDD');
  }

  public packTimestamp(time?: number): string {
    if (!time) return '';
    return this.dt.formatYYMMDDHHmmss(time);
  }

  public packNum(number: number, decimals = 2, round = true): string {
    if (!number) return '0';

    const factor = 10 ** decimals;
    let adjusted = round ? Math.round(number * factor) / factor + '' : Math.trunc(number * factor) / factor + '';

    // Remove decimal and trailing zeros
    if (adjusted.includes('.')) adjusted = adjusted.replace(/\.?0+$/, '');

    return adjusted;
  }

  public packStr(str: string): string {
    if (!str) return '';
    if (!str.includes('|')) return str.trim();
    return str.replaceAll('|', '~~').trim();
  }

  public packStrArray(arr: string[]): string {
    return !arr.length ? '' : arr.join('~');
  }

  // UNPACK FUNCTIONS

  public unpackBool(flag: string, defaultTo: boolean): boolean {
    return !flag ? defaultTo : flag === '1';
  }

  public unpackDate(date: string): string {
    if (this.dateCache.has(date)) return this.dateCache.get(date);

    const unpacked = '20' + date[0] + date[1] + '-' + date[2] + date[3] + '-' + date[4] + date[5];
    this.dateCache.set(date, unpacked);

    return unpacked;
  }

  public unpackTimestamp(date?: string): number {
    if (!date || date.length < 12) return 0; // Ensure date has at least YYMMDDHHMMSS

    // Parse date components directly using charCodeAt for performance
    const year = 2000 + (date.charCodeAt(0) - 48) * 10 + (date.charCodeAt(1) - 48);
    const month = (date.charCodeAt(2) - 48) * 10 + (date.charCodeAt(3) - 48) - 1;  // Months are 0-based in Date.UTC
    const day = (date.charCodeAt(4) - 48) * 10 + (date.charCodeAt(5) - 48);
    const hour = (date.charCodeAt(6) - 48) * 10 + (date.charCodeAt(7) - 48);
    const minute = (date.charCodeAt(8) - 48) * 10 + (date.charCodeAt(9) - 48);
    const second = (date.charCodeAt(10) - 48) * 10 + (date.charCodeAt(11) - 48);

    return Date.UTC(year, month, day, hour, minute, second);
  }

  public unpackNum(number: string): number {
    const num = +number;
    return Number.isNaN(num) ? 0 : num;
  }

  public unpackStr(string: string): string {
    if (!string) return '';
    if (string.includes('~~')) return string.replace(FmtService.DOUBLE_TILDE_REGEX, '|');
    return string;
  }

  public unpackStrArray(str: string): string[] {
    return !str ? [] : str.split('~');
  }

  // SUPPORT FUNCTIONS

  private getNumberFormatter(decimals: number): Intl.NumberFormat {
    const key = `${decimals}`;
    if (!this.numberFormatCache[key]) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
      this.numberFormatCache[key] = formatter;
      return formatter;
    }
    return this.numberFormatCache[key];
  }

  private getCurrencyFormatter(decimals: number): Intl.NumberFormat {
    const key = `USD-${decimals}`;
    if (!this.currencyFormatCache[key]) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
      this.currencyFormatCache[key] = formatter;
    }
    return this.currencyFormatCache[key];
  }

  private getPercentFormatter(decimals: number, showPlus: boolean): Intl.NumberFormat {
    const key = `${decimals}|${showPlus}`;
    if (!this.percentFormatCache[key]) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
        signDisplay: showPlus ? 'exceptZero' : 'auto',
      });
      this.percentFormatCache[key] = formatter;
      return formatter;
    }
    return this.percentFormatCache[key];
  }
}
