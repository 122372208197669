// New fields must be added to the end to match the compressed format in Firestore

export const statsFields = [
  { name: 'sales', type: 'number' },
  { name: 'orders', type: 'integer' },
  { name: 'quantity', type: 'integer' },
  { name: 'profit', type: 'number' },
  { name: 'tippableSales', type: 'number' },
  { name: 'discounts', type: 'number' },
  { name: 'discountOrders', type: 'integer' },
  { name: 'trackedCustomers', type: 'integer' },
  { name: 'tips', type: 'number' },
  { name: 'repeatCustomers', type: 'integer' },
  { name: 'servchgs', type: 'number' },
  { name: 'regularCustomers', type: 'number' },
  { name: 'refunds', type: 'number' },
  { name: 'refundedOrders', type: 'integer' },
  { name: 'refundsWithServchgs', type: 'number' },
  { name: 'trackedOrders', type: 'integer' },
  { name: 'nonRevenue', type: 'integer' },
  { name: 'nonRevenueOrders', type: 'integer' },
  { name: 'uniqueItems', type: 'integer' },
  { name: 'laborCost', type: 'number' },
];

export function decimalsToRound(field: string): number {
  const index = statsFields.findIndex(stat => stat.name === field);
  return index !== -1 && statsFields[index].type === 'number' ? 2 : 0;
}

export interface GroupedStats {
  date: string,
  channelID: string,
  stats: Stats,
}

export class Stats {
  sales = 0;
  orders = 0;
  servchgs = 0;
  quantity = 0;
  discounts = 0;
  discountOrders = 0;
  trackedCustomers = 0;
  repeatCustomers = 0;
  regularCustomers = 0;
  tips = 0;
  tippableSales = 0;
  refunds = 0;
  refundedOrders = 0;
  refundsWithServchgs = 0;
  profit = 0;
  trackedOrders = 0;
  nonRevenue = 0;
  nonRevenueOrders = 0;
  uniqueItems = 0;
  laborCost = 0;
}

export interface StatsArray {
  sales: number[];
  orders: number[];
  servchgs: number[];
  quantity: number[];
  discounts: number[];
  discountOrders: number[];
  trackedCustomers: number[];
  repeatCustomers: number[];
  regularCustomers: number[];
  tips: number[];
  tippableSales: number[];
  refunds: number[];
  refundedOrders: number[];
  refundsWithServchgs: number[];
  profit: number[];
  trackedOrders: number[];
  nonRevenue: number[];
  nonRevenueOrders: number[];
  uniqueItems: number[];
  laborCost: number[];
}

export function initStatsArray(length: number): StatsArray {
  return {
    sales: Array(length).fill(0),
    orders: Array(length).fill(0),
    servchgs: Array(length).fill(0),
    quantity: Array(length).fill(0),
    discounts: Array(length).fill(0),
    discountOrders: Array(length).fill(0),
    trackedCustomers: Array(length).fill(0),
    repeatCustomers: Array(length).fill(0),
    regularCustomers: Array(length).fill(0),
    tips: Array(length).fill(0),
    tippableSales: Array(length).fill(0),
    refunds: Array(length).fill(0),
    refundedOrders: Array(length).fill(0),
    refundsWithServchgs: Array(length).fill(0),
    profit: Array(length).fill(0),
    trackedOrders: Array(length).fill(0),
    nonRevenue: Array(length).fill(0),
    nonRevenueOrders: Array(length).fill(0),
    uniqueItems: Array(length).fill(0),
    laborCost: Array(length).fill(0),
  };
}
