export const CLIENT_SETTINGS_VERSION = 40;

export class ClientSettings {
  _version: number = CLIENT_SETTINGS_VERSION;
  _timezones: string[] = [];
  _connectionMaps: string[] = [];
  authError?= '';
  license: LicenseSettings = {
    billingAgent: '',
    licenseID: '',
    status: '',
  };
  businessName = '';
  startDayOfWeek = 1;
  locations: LocationSettings[] = [];
  channels: ClientChannelSettings[] = [];
  deactivated?: DeactivatedSettings = { locationIDs: [], connectionIDs: [] };
  downgraded?: DowngradedSettings = { locationIDs: [], connectionIDs: [] };
}

export interface Client {
  clientID: string;
  license: LicenseSettings;
  refreshTokens: RefreshToken[];
  connections: ClientConnection[];
  startHour: number;
}

export interface ClientConnection {
  locationID: string;
  connectionID: string;
  sourceID: string;
  country: string;
  lastShiftsChecked: string;
}

export interface RefreshToken {
  sourceID: string;
  locationID: string;
  connectionID: string;
  refreshToken: string;
  accessExpiration: Date;
}

// LICENSE interface
export interface LicenseSettings {
  billingAgent: string;
  licenseID: string;
  status: string;
  fullLicense?: boolean;
  interval?: string;
  trialEndDate?: Date | null;
  currency?: string;
}

// LOCATION interfaces
export class LocationSettings {
  active = false;
  connections: ConnectionSettings[] = [];
  channels: LocationChannelSettings[] = [];
  locationID = '';
  timezone = '';
  dayEndHour = 0;
  country?= '';
  locationName?= '';
  minDate?= '';
  postalCode?= '';
  stateProvince?= '';
  latitude?= 0;
  longitude?= 0;
  historyComplete?= false;
  historyJobs?: string[] = [];
  lastModelDataDate?: string;
  lastModelDate?: string;
  sortOrder?: number;
}

// CONNECTION interfaces
export interface ConnectionSettings {
  active: boolean;
  promptIfInactive?: boolean;
  connectionID: string;
  sourceID: string;
  emailVerified?: boolean;
  id?: string;
  lastChecked?: Date;
  lastShiftsChecked?: string;
  token?: string;
  employees?: string[];
  tenders?: Tender[] | string[];
  orderTypes?: string[];
  custom?: CustomConnectionSettings;
}

export interface Merchant {
  id: string;
  businessName: string;
  locationName: string;
  postalCode: string;
  stateProvince: string;
  country: string;
  timezone: string;
  locations?: MerchantLocation[];
}

export interface MerchantLocation {
  id: string;
  locationName: string;
  stateProvince: string;
  postalCode: string;
  country: string;
  timezone: string;
}

export interface Tender {
  id: string;
  type: string;
  channelID?: string;
}

export interface Employee {
  locationID?: string;
  id: string;
  name: string;
  nickname: string;
  active: boolean;
}

export interface OrderType {
  locationID?: string;
  id: string;
  name: string;
}

export interface CustomConnectionSettings {
  accessToken?: string;
  accessExpiration?: Date;
  tokenExpiration?: Date;  // Replaced by accessExpiration; can be deleted when all clients are updated
  refreshToken?: string;
  merchantLocationID?: string;
  locations?: MerchantLocation[];
}

// CHANNEL interfaces
export interface ClientChannelSettings {
  channelID: string;
  color: string;
}

export interface LocationChannelSettings {
  channelID: string;
  servchgs?: boolean;
  commission?: number;
  mapChannel?: boolean;
}

// DOWNGRADED interfaces

export interface DeactivatedSettings {
  locationIDs: string[];
  connectionIDs: string[];
}

export interface DowngradedSettings {
  locationIDs: string[];
  connectionIDs: string[];
}


// V1 DATA STRUCTURES

export interface ClientSettingsV1 extends Omit<ClientSettings, 'locations'> {
  locations: LocationSettingsV1[];
}

export interface LocationSettingsV1 extends Omit<LocationSettings, 'connections'> {
  connections: ConnectionSettingsV1[];
}

export type ConnectionSettingsV1 = Omit<ConnectionSettings, 'sourceID'> & {
  channelID: string;
}
