import { DestroyRef, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { GoogleService } from '@pages/google/google.service';
import { InactiveService } from '@pages/inactive/inactive.service';
import { SettingsService } from '@settings/settings.service';
import { SourcesService } from '@services/sources.service';
import { SignoutService } from '@services/signout.service';

import { AppState } from '@state/app.state';
import { UserState } from '@state/user.state';

import { CLIENT_SETTINGS_VERSION } from '@shared/settings.interface';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  private params: Params = {};
  private posConnect = false;

  private checkedInactive = false;

  private licenseError = false;

  constructor(
    private appState: AppState,
    private auth: AuthService,
    private destroyRef: DestroyRef,
    private google: GoogleService,
    private inactive: InactiveService,
    private route: ActivatedRoute,
    private router: Router,
    private settings: SettingsService,
    private sources: SourcesService,
    private signout: SignoutService,
    private userState: UserState,
  ) {
    this.route.queryParams
      .pipe(this.signout.takeUntil(this.destroyRef))
      .subscribe(params => {
        this.params = params;
        this.posConnect = params.posConnect === 'true';
      });
  }

  public async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // Hide splash screen when not loading tabs
    if (!state.url.startsWith('/app/')) this.appState.hideSplash$.next();

    const user = this.auth.getUser();
    const loggedIn = !!user;

    if (!loggedIn) {
      await this.router.navigate(['signin'])
        .catch((error) => console.error(`auth.guard.canActivate.signin promise failed: ${error}`));
      return false;
    }

    if (!user?.emailVerified) {
      await this.router.navigate(['verify-email'], { state: { internalRequest: true } });
      return false;
    }

    // Wait until settings are available
    const rawSettings = await this.settings.getRawSettings();

    // Force update if data version is higher than app version
    if (rawSettings._version > CLIENT_SETTINGS_VERSION) {
      await this.router.navigate(['update'], { state: { internalRequest: true } });
      return false;
    }

    // Show license page if primary license has not been purchased
    const licenseExceptions = ['SP0C7Cmt0'];
    if (rawSettings.license.billingAgent === 'CL' && rawSettings.license.status === 'active' &&
      rawSettings.license.fullLicense === false && !licenseExceptions.includes(this.appState.clientID)) {
      if (!this.licenseError) {
        console.error(`Primary license issue with ${this.appState.clientID} / ${rawSettings.license.licenseID}.`);
        this.licenseError = true;
      }
      await this.router.navigate(['license'], { state: { internalRequest: true } });
      return false;
    }

    // Allow access to pos-connect or google as long as the user is logged in
    if (state.url === '/pos-connect' || state.url === '/google') return true;

    if (!this.settings.hasAPIConnection) {
      await this.router.navigate(['pos-connect'], { state: { internalRequest: true, shop: this.params.shop ?? '' } });
      return false;
    } else if (this.settings.errors.includes('NOT_LICENSED')) {
      if (state.url === '/billing') return true;
      if (this.posConnect) {
        await this.router.navigate(['billing'], { state: { internalRequest: true, referringURL: 'pos-connect' } });
      } else {
        await this.router.navigate(['billing'], { state: { internalRequest: true } });
      }
      return false;
    }

    // Wait for userState to be initialized before validating user access
    await this.userState.isInitialized();

    if (!this.appState.activeLocationIDs.length) {
      await this.router.navigate(['locations'], { state: { internalRequest: true } });
      return false;
    }

    if (this.appState.activeLocationIndex >= 0) {
      const validatedSettings = await this.settings.getValidatedSettings();
      const location = this.settings.getAuthLocation(validatedSettings.locations,
        this.appState.activeLocationIndex);

      if (location && this.google.promptForGoogle(validatedSettings.locations) && this.userState.canEdit['settings']) {
        await this.router.navigate(['google']);
        return false;
      }

      if (!location?.minDate && location?.connections.some(connection =>
        this.sources.getSetting(connection.sourceID, 'source') === 'api')) {
        await this.router.navigate(['retrieving'], { state: { internalRequest: true } });
        return false;
      }
    }

    // Show inactive page if any Clover connections are inactive
    if (!this.checkedInactive) {
      this.checkedInactive = true;
      this.inactive.update(rawSettings);
    }
    if (this.inactive.connections.length && this.inactive.connections[0].locationName) {
      await this.router.navigate(['inactive'], { state: { internalRequest: true } });
      return false;
    }

    return loggedIn;
  }
}
